import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from ".";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { APIRoutes } from "../../utils/constants";
import { useParams } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export default function Payment() {
  const configData = useConfig();
  const stripeKey: any = configData?.stripePublishableKey;
  const { clientSecret, plan_id, paymentId }: any = useParams();

  const stripePromise = loadStripe(stripeKey);
  const [plandetails, setPlanDetails] = useState<any>({});
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [option, setOption] = useState({});
  const appearance = {
    theme: "stripe",
  };

  const GetPlanDetails = async () => {
    setIsSkeleton(true);
    try {
      if (plan_id == 0) {
        const res = await HttpService.get(
          APIRoutes.GetGiftCardDetails.replace(":id", paymentId)
        );

        setOption({
          clientSecret,
          appearance,
        });
        const plan = res?.data?.data;
        setPlanDetails({
          ...plandetails,
          price: `$${plan?.GiftAmount}`,
          name: plan?.RecipientsName,
          date: plan?.DeliveryDate,
          message: plan?.SortMessage,
          email: plan?.RecipientsEmail,
        });
        setIsSkeleton(false);
      } else {
        const plan: any = await HttpService.get(
          APIRoutes.GetPlansByID.replace(":id", plan_id)
        );

        if (plan?.data?.data?.plan_type_id == 3) {
          setOption({
            clientSecret,
            appearance,
          });
        } else {
          if (clientSecret !== "0") {
            setOption({
              clientSecret,
              appearance,
            });
          } else
            setOption({
              mode: "subscription",
              amount: Number(plan?.data?.data?.price_in_currency),
              currency: plan?.data?.data?.currency,
              appearance,
            });
        }

        setPlanDetails(plan?.data?.data);
        setIsSkeleton(false);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    GetPlanDetails();
  }, []);
  return (
    <div className="App">
      {!isSkeleton && (
        <Elements options={option} stripe={stripePromise}>
          <CheckoutForm plandetails={plandetails} />
        </Elements>
      )}
    </div>
  );
}
