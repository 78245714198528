import {
  Box,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import Slider from "react-slick";
import GameCard from "../../../Common/Component/GameCard";
import GameCardV2 from "../../../Common/Component/GameCardV2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useConfig from "../../../hooks/useConfig";
//@ts-ignore
import styles from "./GameListHomePage.module.css";
const GameListHomePage = ({
  title,
  subtitle,
  games,
  subscriptionDetails,
  isSubscribed,
  auth,
  gameStatus,
}: {
  title: string;
  subtitle: string;
  games: any[];
  gameStatus: ReactElement;
  subscriptionDetails: any;
  auth: any;
  isSubscribed: any;
}) => {
  const configData = useConfig();
  const PreviousBtn = (props: any) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            bgcolor: configData.primary,
            border: "1px solid #E7E7E7",
            p: 0.5,
            zIndex: 10,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const NextBtn = (props: any) => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            border: "1px solid #E7E7E7",
            rotate: "180deg",
            p: 0.5,
            bgcolor: configData.primary,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1440")
  );
  const [sliderRef, setSliderRef] = useState<any>(null);
  //  xs: 0,
  //       sm: 567,
  //       md: 768,
  //       lg: 992,
  //       xl: 1440,
  const sliderSettings = {
    // removes default buttons
    slidesToShow: 4,

    autoplay: false,
    autoplaySpeed: 2500,
    slidesToScroll: 2,
    infinite: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: games?.length > 3 ? 3 : games?.length,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h6"
          sx={{
            textTransform: "none",
            fontStyle: "normal",
            fontWeight: 600,
            px: 3,
            py: 1,
          }}
        >
          {title}
        </Typography>
        {!!games?.length ? (
          <Box sx={{ mb: 2, px: { xs: 0, sm: 2 } }}>
            <Box className={styles.slickContainer}>
              <Slider
                ref={setSliderRef}
                {...sliderSettings}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
                dotsClass="slick-dots custom-indicator"
              >
                {games?.map((game: any) => (
                  <GameCardV2
                    gameStatus={gameStatus}
                    subtitle={subtitle}
                    subscriptionDetails={subscriptionDetails}
                    game={game}
                    auth={auth}
                    isSubscribed={isSubscribed}
                  />
                ))}
                {games?.length === 3 && !isMobile && <Box></Box>}
                {games?.length === 2 &&
                  !isMobile &&
                  new Array(2).fill(0).map((el) => <Box></Box>)}
                {games?.length === 1 &&
                  !isMobile &&
                  new Array(3).fill(0).map((el) => <Box></Box>)}
              </Slider>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "start", px: 3 }}>
            <Typography
              sx={{
                textTransform: "none",
                fontStyle: "normal",
              }}
            >
              There are no {subtitle?.toLowerCase()} games right now
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default GameListHomePage;
