import React, { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useConfig from "../../../../hooks/useConfig";
import axios from "../../../../utils/axios";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
// @ts-ignore
import videojs from "video.js";
import "video.js/dist/video-js.css";

import lvp from "@liniusaustpty/linius-video-player";
import "@liniusaustpty/linius-video-player/dist/lvp.css";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
const VideoPlayerForChannel = ({
  setCurrentTime,
  playerRef,
  setActiveClip,
  pageData,
  videoRef,
  page,
  sideBarComponent,
  setPage,
  checked,
  currentVideoData,
  setCurrentVideoData,
  videoList,
  assetId,
  offset,
  duration,
  accessToken,
  xApiKey,
}: any) => {
  const configData = useConfig();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("913"));
  const isTab = useMediaQuery((theme: any) => theme.breakpoints.down("1025"));
  const [openDialog, setOpenDialog] = useState(false);
  const [isSideBarClosed, setIsSideBarClosed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handleOpenDiaLog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    const videoJsOptions = {
      controls: true,
      autoplay: true,
      fluid: true,

      poster: configData.logoUrl,
    };

    if (!playerRef.current) {
      // @ts-ignore
      playerRef.current = lvp.player(videoRef.current, videoJsOptions, true);
      playerRef.current.on("fullscreenchange", () => {
        setIsFullscreen(playerRef.current.isFullscreen());
      });
    }
    // @ts-ignore

    // videojs.Vhs.xhr.beforeRequest = (options: any) => {
    //   if (options.uri?.includes("/assembly/ldash")) {
    //     return {
    //       ...options,
    //       headers: {
    //         "Content-Type": "application/x-mpegURL",
    //         Authorization: `Bearer ${accessToken}`,
    //         "x-api-key": xApiKey,
    //       },
    //     };
    //   }
    //   return options;
    // };
    var executed = false;
    playerRef.current.on("ended", function () {
      if (!executed) {
        const findIndex = videoList.findIndex(
          (el: any) => el.id == currentVideoData.id
        );
        if (findIndex == videoList.length - 1) {
          return;
        }
        if (!pageData?.last) {
          setPage(page + 1);
        } else {
          setPage(1);
        }

        //  playerRef.current.play();
        executed = true;
      }
    });
    const findCurrentClipTime = (el: any, index: any) => {
      let sum = 0;
      for (var i = 0; i < index; i++) {
        sum += el?.duration;
      }
      return sum;
    };
    playerRef.current.on("timeupdate", function () {
      var hasPlayedTime = playerRef.current.currentTime();
      setCurrentTime(hasPlayedTime);

      const findIndex = videoList.findIndex(
        (el: any, index: any) =>
          hasPlayedTime >= findCurrentClipTime(el, index) &&
          hasPlayedTime < findCurrentClipTime(el, index) + el?.duration
      );

      if (findIndex != -1) {
        setActiveClip(videoList[findIndex]?.id);
      }
    });
    playerRef.current.src({
      src: `${configData.liniusUrl}/assembly/ldash?id=${videoList
        ?.map((el: any) => el?.assetId)
        .join(",")}&o=${videoList
        ?.map((el: any) => el?.offset)
        .join(",")}&d=${videoList?.map((el: any) => el?.duration).join(",")}`,
      type: "linius/ldash",
    });
    // playerRef.current.src({
    //   src: `${configData.liniusUrl}/assembly/ldash?id=1690197,${videoList
    //     ?.map((el: any) => el?.assetId)
    //     .join(",1690197,")},1690197&o=0,${videoList
    //     ?.map((el: any) => el?.offset)
    //     .join(",0,")},0&d=-1,${videoList
    //     ?.map((el: any) => el?.duration)
    //     .join(",-1,")},-1`,
    //   type: "linius/ldash",
    // });
  }, [assetId, offset, duration, accessToken, xApiKey]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  useEffect(() => {
    const videoPlayer = document.getElementById("linius-video-player");
    if (videoPlayer) {
      if (checked) {
        videoPlayer.style.height = "660px";
      } else {
        videoPlayer.style.height = "490px";
      }
    }
  }, [checked]);
  const handleClose = () => {
    const currentDiv = document.getElementById("sidebar-video-player");
    const currentDivExpand = document.getElementById("expand-button");
    if (currentDiv) {
      currentDiv.style.maxHeight = "0px";
      currentDiv.style.maxWidth = "0px";
      currentDiv.style.transition = "0.2s all ease-in";
    }
    if (currentDivExpand) {
      currentDivExpand.style.top = isMobile ? "40px" : "20px";
      currentDivExpand.style.right = "20px";
      currentDivExpand.style.rotate = "90deg";
      currentDivExpand.style.transition = "0.2s all ease-in";
    }
    setIsSideBarClosed(true);
  };
  const handleOpen = () => {
    const currentDiv = document.getElementById("sidebar-video-player");
    const currentDivExpand = document.getElementById("expand-button");
    if (currentDiv) {
      currentDiv.style.maxHeight = isTab ? "80%" : "87%";
      currentDiv.style.maxWidth = isMobile ? "250px" : "28%";
      currentDiv.style.transition = "0.2s all ease-in";
    }
    if (currentDivExpand) {
      currentDivExpand.style.top = "70px";
      currentDivExpand.style.rotate = "270deg";
      currentDivExpand.style.right = isMobile ? "230px" : "26.5%";
      currentDivExpand.style.transition = "0.2s all ease-in";
    }
    setIsSideBarClosed(false);
  };
  useEffect(() => {
    if (isFullscreen && isMobile) {
      setIsSideBarClosed(false);
      handleOpen();
    } else if (isMobile) {
      setIsSideBarClosed(true);
      handleClose();
    } else {
      setIsSideBarClosed(false);
      handleOpen();
    }
  }, [isMobile, checked, isFullscreen]);
  return (
    <div data-vjs-player>
      <video
        id="linius-video-player"
        controls
        style={{
          position: "relative",
        }}
        width="100%"
        ref={videoRef}
        className="video-js vjs-default-skin vjs-big-play-centered"
      />
      {checked && (
        <IconButton
          id="expand-button"
          onClick={
            isMobile && !isFullscreen
              ? handleOpenDiaLog
              : isSideBarClosed
              ? handleOpen
              : handleClose
          }
          sx={{
            rotate: "270deg",
            position: "absolute",
            right: isMobile ? "230px" : "26.5%",
            top: 70,
            zIndex: 101,
          }}
        >
          <ExpandCircleDownIcon
            sx={{ color: configData.tertiary, fontSize: 32 }}
          />
        </IconButton>
      )}
      {((checked && !isMobile) || (checked && isFullscreen && isMobile)) && (
        <Box
          id="sidebar-video-player"
          sx={{
            position: "absolute",
            zIndex: 100,
            maxHeight: isTab ? 0.8 : 0.87,
            maxWidth: isMobile ? "250px" : "28%",
            width: 1,
            right: 4,
            height: 1,
            top: 4,
            bottom: 0,
            overflow: "auto",
          }}
        >
          {sideBarComponent}
        </Box>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            the feature is only available when the video player is in full
            screen mode
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Close
          </Button>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                bgcolor: configData.primary,
              },
            }}
            onClick={() => {
              playerRef.current.requestFullscreen(); // Enter fullscreen

              handleCloseDialog();
            }}
          >
            Open Fullscreen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoPlayerForChannel;
