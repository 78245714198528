import {
  TextField,
  Box,
  Dialog,
  Grid,
  Button,
  FormControl,
  Select,
  MenuList,
  MenuItem,
  InputLabel,
  Typography,
  Stack,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import HttpService from "../../../../../hooks/Https-services";
import useConfig from "../../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormHelpText from "rsuite/esm/FormHelpText";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";
import moment from "moment";
import momentTimezone from "moment-timezone";

const userTimeZone = momentTimezone.tz.guess();
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const SliderForm = ({ initalValue, fileUrl, setFileUrl }: any) => {
  const configData = useConfig();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [channelOption, setChannelOption] = useState([]);
  const [selectedType, setSelectedType] = useState(initalValue?.type);
  const editorRef: any = useRef(null);
  const editorRefMobile: any = useRef(null);
  const [vodGames, setVodGames] = useState([]);
  const [liveGame, setLiveGames] = useState([]);
  const [searchFilter, setFilter] = useState<any>(null);
  const [gameOptions, setGameOptions] = useState<any>([]);
  const [viewMode, setViewMode] = useState("window");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validate = (values: any) => {
    let errors: any = {};

    if (!values.seasonId && selectedType === "channel") {
      errors["seasonId"] = "This field is Required";
    }

    if (!values.gameId && selectedType === "game") {
      errors["gameId"] = "This field is Required";
    }
    if (!values.images && id == 0) {
      errors["images"] = "This field is Required";
    }
    return errors;
  };
  const formik: any = useFormik({
    initialValues: initalValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        var bodyFormData = new FormData();
        bodyFormData.append("Name", values.name);
        bodyFormData.append("Description", values.description);
        bodyFormData.append("DisplayText", editorRef.current.getContent());
        bodyFormData.append(
          "MobileDisplayText",
          editorRefMobile.current.getContent()
        );
        bodyFormData.append("FontSize", values.fontSize);
        bodyFormData.append("FontColor", values.fontColor);
        bodyFormData.append("Priority", values.priority);
        bodyFormData.append(
          "Type",
          selectedType === "channel" ? "channel" : "game"
        );
        bodyFormData.append(
          "WatchUrl",
          selectedType === "channel"
            ? values.seasonId
            : JSON.stringify({
                game_id: values.gameId?.game_id,
                away_team: values.gameId?.away_team,
                home_team: values.gameId?.home_team,
                away_team_id: values.gameId?.away_team_id,
                home_team_id: values.gameId?.home_team_id,
              })
        );

        if (id != 0) {
          if (values.images) {
            bodyFormData.append("Image", values.images);
          }
          const res = await HttpService.postImage(
            APIRoutes.UpdateHomeSlider.replace(":id", id),
            bodyFormData
          );
        } else {
          if (!values.images) {
            toast.error("Please Upload Image");
            return;
          }
          bodyFormData.append("Image", values.images);

          const res = await HttpService.postImage(
            APIRoutes.CreateSlider,
            bodyFormData
          );
        }
        navigate(PageRoutes.SliderSetting);
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    },
  });
  const FetchChannels = async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );

      setChannelOption(result.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    FetchChannels();
  }, []);
  const FetchTeamLIVE = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "live",

        page: 1,
        search: searchFilter,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setLiveGames(data);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };

  const FetchTeamVOD = async (searchFilter: any) => {
    try {
      const params: any = {
        customer: configData.customer,
        status: "vod",
        page: 1,
        search: searchFilter,
        limit: 5,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });

      const data = result.data.data;
      setVodGames(data);
      //   setPage(page + 1);
    } catch (error) {
    } finally {
      //   setIsLoading(false);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamVod = useCallback(
    _.debounce(FetchTeamVOD, 500),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchUserFavouriteTeamLive = useCallback(
    _.debounce(FetchTeamLIVE, 500),
    []
  );
  useEffect(() => {
    debouncedFetchUserFavouriteTeamVod(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamVod, searchFilter]);
  useEffect(() => {
    debouncedFetchUserFavouriteTeamLive(searchFilter);

    // Cleanup function to cancel the previous debounced function
  }, [debouncedFetchUserFavouriteTeamLive, searchFilter]);
  const checkPhotoIsInSize = (fileUpload: any) => {
    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(fileUpload[0]);
    reader.onload = function (e: any) {
      //Initiate the JavaScript Image object.
      var image: any = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        if (height == 792 && width == 2560) {
          formik.setFieldValue("images", fileUpload[0]);
          setFileUrl(URL.createObjectURL(fileUpload[0]));

          return true;
        } else {
          toast.error("Images must be 2560px(width) x 792px(height)");
          return false;
        }
      };
    };
  };
  const renderOptions = useCallback(() => {
    const optionsVod = vodGames.map((option: any) => {
      const firstLetter = "On Demand";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    const optionsLive = liveGame.map((option: any) => {
      const firstLetter = "Live and Upcoming";
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });
    setGameOptions([...optionsVod, ...optionsLive]);
  }, [vodGames, liveGame]);
  useEffect(() => {
    renderOptions();
  }, [renderOptions]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          backgroundImage: `url(${fileUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          maxWidth: viewMode === "mobile" ? 412 : 1,
          height: viewMode === "mobile" ? "125px" : "29vw",
          mx: viewMode === "mobile" ? "auto" : 1,
          mt: 0.5,
        }}
        // className={`carousel-item`}
      >
        <div
          className="carousel-content"
          style={{
            gap: 8,
            backgroundColor: formik.values.fontColor,
            background: `linear-gradient(to right, ${formik.values.fontColor} 0%, ${formik.values.fontColor} 60%, transparent 100%)`,
            width: viewMode === "mobile" ? "60%" : "40%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              maxWidth: 0.65,

              display: viewMode === "mobile" ? "none" : "block",
              textTransform: "none",
              ">p": { fontStyle: "normal", textTransform: "none" },
            }}
            dangerouslySetInnerHTML={{ __html: formik.values.displayText }}
          ></Box>

          <Box
            sx={{
              display: viewMode === "mobile" ? "block" : "none",
              textTransform: "none",
              ">p": { fontStyle: "normal", textTransform: "none" },
            }}
            dangerouslySetInnerHTML={{
              __html: formik.values.mobileDisplayText,
            }}
          ></Box>

          <Button
            sx={{
              display: viewMode === "mobile" ? "none" : "flex",
            }}
            variant="contained"
            size="medium"
            // onClick={() => handleWatch(JSON.parse(el?.WatchUrl), el?.Type)}
          >
            WATCH
          </Button>
          <Button
            sx={{
              display: viewMode === "mobile" ? "flex" : "none",
            }}
            variant="contained"
            size="small"
            // onClick={() => handleWatch(JSON.parse(el?.WatchUrl), el?.Type)}
          >
            WATCH
          </Button>
        </div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
        <IconButton
          sx={{
            bgcolor: viewMode === "window" ? configData.primary : "",
            ":hover": {
              bgcolor: viewMode === "window" ? configData.primary : "",
            },
            color: viewMode === "window" ? configData.tertiary : "",
          }}
          onClick={() => setViewMode("window")}
        >
          <LaptopMacIcon />
        </IconButton>
        <IconButton
          sx={{
            bgcolor: viewMode === "mobile" ? configData.primary : "",
            ":hover": {
              bgcolor: viewMode === "mobile" ? configData.primary : "",
            },
            color: viewMode === "mobile" ? configData.tertiary : "",
          }}
          onClick={() => setViewMode("mobile")}
        >
          <PhoneIphoneIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Typography variant="subtitle1">
          {id == 0 ? "Create" : "Update"} Slider
        </Typography>
      </Box>
      <Grid sx={{ p: 4, maxWidth: 800, mx: "auto" }} container spacing={2}>
        <Grid item xs={12}>
          <button
            onClick={() => navigate(PageRoutes.SliderSetting)}
            style={{
              cursor: "pointer",
              width: "100px",
              padding: "8px",
              margin: "10px 0px",
              fontSize: "14px",
            }}
            className={configData.buttonCommonClass}
          >
            Go Back
          </button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            placeholder="Name"
            id="name"
            name="name"
            type={"text"}
            label={"Name"}
            error={formik.errors.name ? true : false}
            helperText={formik?.errors?.name}
            value={formik.values.name}
            variant="outlined"
            inputProps={{
              "aria-label": "Name",
            }}
            aria-describedby="outlined-weight-helper-text"
            onChange={(e: any) => {
              formik.handleChange(e);
              formik.errors.name = "";
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            placeholder="Background Color"
            id="fontColor"
            name="fontColor"
            type={"color"}
            label={"Background Color"}
            error={formik.errors.fontColor ? true : false}
            helperText={formik?.errors?.fontColor}
            value={formik.values.fontColor}
            variant="outlined"
            inputProps={{
              "aria-label": "Name",
            }}
            aria-describedby="outlined-weight-helper-text"
            onChange={(e: any) => {
              formik.handleChange(e);
              formik.errors.fontColor = "";
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <RadioGroup
              row
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="channel"
                control={<Radio />}
                label="Link To Channel"
              />
              <FormControlLabel
                value="game"
                control={<Radio />}
                label="Link To Game"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedType === "channel" ? (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Target Channel
              </InputLabel>
              <Select
                label={"Select Target Channel"}
                placeholder="Season Id"
                id="seasonId"
                name="seasonId"
                error={formik.errors.seasonId ? true : false}
                value={formik.values.seasonId}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  formik.errors.seasonId = "";
                }}
              >
                {channelOption?.map((el: any) => (
                  <MenuItem value={el.channel_id}>{el?.channel_name}</MenuItem>
                ))}
              </Select>
              <FormHelpText color="error">
                {" "}
                {formik?.errors?.seasonId}
              </FormHelpText>
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                id="grouped-demo"
                onChange={(e: any, value: any) => {
                  formik.setFieldValue("gameId", value);
                  formik.errors.gameId = "";
                }}
                freeSolo
                options={gameOptions?.sort(
                  (a: any, b: any) =>
                    -b.firstLetter?.localeCompare(a.firstLetter)
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.game_id}>
                    {`${option.away_team} @ ${option.home_team} (${moment(
                      option.game_date
                    ).format("MMM. DD")}  ${momentTimezone
                      .tz(
                        option.game_date_time,
                        "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                        "America/New_York"
                      )
                      .tz(userTimeZone)
                      .format("h:mm a z")
                      .toUpperCase()})`}
                  </li>
                )}
                filterOptions={(options, state) =>
                  options.filter(
                    (option) =>
                      option.away_team
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.home_team
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      option.game_id.toString().includes(state.inputValue) // Enable search by gameId
                  )
                }
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) =>
                  `${option.away_team} @ ${option.home_team} `
                }
                value={formik.values.gameId}
                renderInput={(params) => (
                  <TextField
                    onChange={(e: any) => setFilter(e.target.value)}
                    {...params}
                    label="Select Target Game"
                  />
                )}
              />
              <FormHelpText color="error">
                {" "}
                {formik?.errors?.gameId}
              </FormHelpText>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={{ width: 1 }}>
            <FormLabel>Display Text For Large Screen</FormLabel>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_KEY}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={formik.values.displayText}
              // onMouseUp={handlerFunction}
              onChange={() => {
                formik.setFieldValue(
                  "displayText",
                  editorRef.current.getContent()
                );
              }}
              init={{
                branding: false,
                min_height: 300,
                toolbar_mode: "wrap",
                statusbar: false,
                height: 300,
                width: "100%",

                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table mergetags | align lineheight | checklist numlist bullist indent outdent | charmap",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ width: 1 }}>
            <FormLabel>Display Text For Mobile Screen</FormLabel>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_KEY}
              onInit={(evt, editor) => (editorRefMobile.current = editor)}
              initialValue={formik.values.mobileDisplayText}
              // onMouseUp={handlerFunction}
              onChange={() => {
                formik.setFieldValue(
                  "mobileDisplayText",
                  editorRefMobile.current.getContent()
                );
              }}
              init={{
                branding: false,
                min_height: 300,
                toolbar_mode: "wrap",
                statusbar: false,
                height: 300,
                width: "100%",

                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table mergetags | align lineheight | checklist numlist bullist indent outdent | charmap",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload Image
              <VisuallyHiddenInput
                type="file"
                accept=".png"
                onChange={(e) => checkPhotoIsInSize(e.target.files)}
              />
            </Button>

            <Typography variant="caption" color={"warning"}>
              Sliders must be PNGs and 2560px(width) x 792px(height)
            </Typography>
          </Stack>
          {fileUrl && (
            <Box sx={{ width: "200px", py: 2 }}>
              <img onClick={handleClickOpen} src={fileUrl} width={"100%"} />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              width: 1,

              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ maxWidth: "200px" }}>
              <button
                type="submit"
                //   onClick={() => handelDeleteSlider(row?.ID)}
                style={{
                  margin: "20px 0px",
                  cursor: "pointer",
                }}
                className={configData.buttonCommonClass}
              >
                Submit
              </button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={fileUrl} width={"100%"} />
      </Dialog>
    </form>
  );
};
export default SliderForm;
