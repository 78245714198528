import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import MyAccount from "..";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import useCheckSubscibed from "../../../hooks/useCheckSubscibed";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
} from "../../../utils/constants";
import moment from "moment";
import Visa from "../../../assets/img/visa.png";
import Code from "../../../assets/img/code.png";
import jcb from "../../../assets/img/jcb.png";
import MasterCardd from "../../../assets/img/mastercard.png";
import dummyCard from "../../../assets/img/dummyCard.svg";
import americanExpress from "../../../assets/img/americanExpress.png";
import discover from "../../../assets/img/discover.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import React from "react";
import momentTimezone from "moment-timezone";
import PaymentUpdate from "../PaymentUpdate";
import CardDetails from "../PaymentUpdate/CardDetails";
import { Close } from "@mui/icons-material";
import useConfig from "../../../hooks/useConfig";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import DownloadPdfComponent from "../../../Common/Component/DownloadPdfComponent";
import HttpService from "../../../hooks/Https-services";
import PauseSub from "./PauseSub";

const PaymentInformation = () => {
  const userData = useCommonDetails();
  const [code, setCode] = useState("");
  const [coupenCode, setCoupenCode] = useState("");
  const navigate = useNavigate();
  const [paymentHistory, setPaymentHistory] = useState<any>([]);

  const [currentPlan, setCurrentPlan] = useState({} as any);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [userDownloadCredit, setUserDownloadCredit] = useState({} as any);
  const [currentPaymentDetials, setCurrentPaymentDetails] = useState<any>({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({} as any);
  const { handleChange, isLoadingPayment }: any = useContext(LoadingContext);
  const [customerDetails, setIsCustomerDetails] = useState<any>({});
  const [isRefresh, setIsRefresh] = useState(false);
  const handleChangeRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const GetUserAccountDetails = async () => {
    setIsSkeleton(true);
    try {
      const res = await HttpService.get(APIRoutes.GetUserAccountDetails);
      const data = res?.data?.data;
      setCurrentPlan(data);
      setUserDownloadCredit(data?.UsersConfiguration);
      setCurrentPaymentDetails(data?.Subscription?.default_payment_method);
      setSubscriptionDetails(data?.Subscription);
      setPaymentHistory(data?.Payments);
      setIsCustomerDetails(data?.CustomerDetails);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const showPatmentIcon = (cardBrand: any) => {
    let imageSrc;

    switch (cardBrand) {
      case "visa":
        imageSrc = Visa; // Replace with the actual path to the Visa card image
        break;
      case "mastercard":
        imageSrc = MasterCardd; // Replace with the actual path to the Mastercard image
        break;
      case "amex":
      case "amex2":
        imageSrc = americanExpress; // Replace with the actual path to the American Express image
        break;
      case "discover":
      case "discover2":
        imageSrc = discover; // Replace with the actual path to the American Express image
        break;
      case "jcb":
        imageSrc = jcb; // Replace with the actual path to the American Express image
        break;
      // Add more cases for other card brands if needed
      default:
        imageSrc = dummyCard; // Replace with the default image path
        break;
    }
    return imageSrc;
  };

  const configData = useConfig();
  const handleMangePlan = async () => {
    handleChange(true);
    const cancel_at_period_end =
      !subscriptionDetails?.cancel_at_period_end &&
      !subscriptionDetails?.canceled_at
        ? true
        : false;
    try {
      if (subscriptionDetails?.pause_collection) {
        const res = await HttpService.post(
          `${APIRoutes.PauseSubscription.replace(
            ":id",
            subscriptionDetails.id
          )}?isPause=false`,
          {
            date: "",
          }
        );
      }
      if (
        subscriptionDetails?.status === "past_due" ||
        subscriptionDetails?.status === "unpaid" ||
        subscriptionDetails?.status === "incomplete"
      ) {
        const res = await HttpService.post(
          `${APIRoutes.DeleteUsersSub.replace(":id", subscriptionDetails?.id)}`,
          {}
        );
      } else {
        const response: any = await HttpService.post(
          APIRoutes.ChangePlanDetails,
          {
            subscriptionId: subscriptionDetails?.id,
            cancel_at_period_end: cancel_at_period_end,
          }
        );
      }

      handleChange(false);
      handleChangeRefresh();
    } catch (error: any) {
      handleChange(false);
    }
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCode = async () => {
    if (!code) {
      toast.error("Please enter a valid code");
      return;
    }
    try {
      const res = await HttpService.post(APIRoutes.ApplyGiftCode, {
        giftCode: code,
      });
      if (res) {
        toast.success("Gift Code Applied Successfull");
        setCode("");
      }
      handleChangeRefresh();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handleApplyCode = async () => {
    if (!coupenCode) {
      toast.error("Please enter a valid code");
      return;
    }
    try {
      const res = await HttpService.post(APIRoutes.ApplyCouponToSubscription, {
        couponId: coupenCode,
        subscriptionId: subscriptionDetails?.id,
      });
      if (res) {
        toast.success("Coupen Code Applied Successfull");
        setCoupenCode("");
      }
      handleChangeRefresh();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    Promise.all([GetUserAccountDetails()]).finally(() => setIsSkeleton(false));
  }, [isRefresh]);
  if (isSkeleton) {
    return (
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          size={48}
          sx={{
            color: "#bf311a",
          }}
        />
      </Box>
    );
  }

  return (
    <MyAccount>
      {paymentHistory?.length > 0 ? (
        <>
          <div className="col-sm-1 "></div>

          <div className="container">
            <div className="row">
              <Box sx={{ mb: 2 }}></Box>

              <div className="col-sm-12 text-left">
                {userDownloadCredit &&
                  currentPlan?.PlanTypeID == INSIDER_PLAN_ID &&
                  userDownloadCredit?.ClipsCredit < 1 && (
                    <Box>
                      <Alert
                        sx={{ my: 2, whiteSpace: "normal" }}
                        severity="warning"
                      >
                        You have exceeded your download credits for the month.
                        Please contact our support team.
                      </Alert>
                    </Box>
                  )}
                <Box>
                  <Typography variant="h1">Payment Information</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontStyle: "normal",
                      textTransform: "none",
                    }}
                  >
                    Update your payment information or change your plan
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Box>
                    <Box>
                      <Typography variant="h1">My Plan</Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          fontStyle: "normal",
                          textTransform: "none",
                        }}
                      >
                        Your current plan information is below
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        py: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      {subscriptionDetails &&
                        !subscriptionDetails?.pause_collection && (
                          <Typography
                            variant="h3"
                            sx={{
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {currentPlan?.PlanTypeID == 3
                              ? "Single Game Access"
                              : `${subscriptionDetails?.plan?.interval}ly Access`}
                            {subscriptionDetails && (
                              <Chip
                                sx={{ ml: 0.5 }}
                                label={subscriptionDetails?.status
                                  ?.split("_")
                                  ?.join(" ")}
                                color={
                                  subscriptionDetails?.status != "active"
                                    ? "error"
                                    : "success"
                                }
                              />
                            )}
                            {subscriptionDetails &&
                              (subscriptionDetails?.status === "past_due" ||
                                subscriptionDetails?.status === "unpaid" ||
                                subscriptionDetails?.status ===
                                  "incomplete") && (
                                <Button
                                  color="success"
                                  sx={{
                                    ":hover": {
                                      color: "common.white",
                                    },
                                    ml: { xs: 0, md: 1 },
                                  }}
                                  href={PageRoutes.paymentPage
                                    .replace(
                                      ":clientSecret",
                                      subscriptionDetails?.latest_invoice
                                        ?.payment_intent?.client_secret
                                    )
                                    .replace(
                                      ":plan_id",
                                      subscriptionDetails?.plan?.id
                                    )
                                    .replace(":paymentId", currentPlan?.ID)}
                                  variant="contained"
                                >
                                  Complete Payment
                                </Button>
                              )}
                          </Typography>
                        )}
                      {subscriptionDetails?.pause_collection ? (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            textTransform: "none",
                          }}
                        >
                          Your plan is paused until{" "}
                          {moment
                            .unix(
                              subscriptionDetails?.pause_collection?.resumes_at
                            )
                            .format("MMM DD, YYYY")}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            textTransform: "none",
                          }}
                        >
                          {subscriptionDetails
                            ? `${
                                subscriptionDetails?.discount?.coupon
                                  ?.amount_off
                                  ? subscriptionDetails?.plan?.amount -
                                      subscriptionDetails?.discount?.coupon
                                        ?.amount_off <
                                    0
                                    ? 0
                                    : (subscriptionDetails?.plan?.amount -
                                        subscriptionDetails?.discount?.coupon
                                          ?.amount_off) /
                                      100
                                  : subscriptionDetails?.discount?.coupon
                                      ?.percent_off
                                  ? (
                                      (subscriptionDetails?.plan?.amount -
                                        (subscriptionDetails?.plan?.amount /
                                          100) *
                                          subscriptionDetails?.discount?.coupon
                                            ?.percent_off) /
                                      100
                                    ).toFixed(2)
                                  : subscriptionDetails?.plan?.amount / 100
                              } ${subscriptionDetails?.plan?.currency?.toUpperCase()} (next payment 
                        ${momentTimezone
                          .unix(subscriptionDetails?.current_period_end)
                          .tz("America/New_York")
                          .format("YYYY-MM-DD")}
                        )`
                            : "No upcoming payments."}
                        </Typography>
                      )}
                      {subscriptionDetails?.discount?.coupon && (
                        <Typography
                          color={"success"}
                          sx={{ color: "green" }}
                          variant="caption"
                        >
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <img src={Code} width={"20px"} />{" "}
                          {subscriptionDetails?.discount?.coupon?.id} applied (
                          -
                          {!!subscriptionDetails?.discount?.coupon
                            ?.amount_off &&
                            `${
                              subscriptionDetails?.discount?.coupon
                                ?.amount_off / 100
                            } ${subscriptionDetails?.discount?.coupon?.currency?.toUpperCase()}`}
                          {!!subscriptionDetails?.discount?.coupon
                            ?.percent_off &&
                            `${subscriptionDetails?.discount?.coupon.percent_off}%`}
                          )
                        </Typography>
                      )}
                      {subscriptionDetails &&
                        !subscriptionDetails?.pause_collection && (
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 600,
                              fontStyle: "normal",
                              textTransform: "none",
                            }}
                          >
                            {!subscriptionDetails?.cancel_at_period_end &&
                            !subscriptionDetails?.canceled_at
                              ? `Renew 
                        ${momentTimezone
                          .unix(subscriptionDetails?.current_period_end)

                          .format("YYYY-MM-DD hh:mm A")}`
                              : "This plan will not auto-renew"}
                          </Typography>
                        )}
                      {subscriptionDetails && (
                        <div className="value">
                          <button
                            style={{ width: "auto", fontSize: "16px" }}
                            className={configData.buttonCommonClass}
                            onClick={handleMangePlan}
                          >
                            {!subscriptionDetails?.cancel_at_period_end
                              ? "Cancel"
                              : "Renew plan"}
                          </button>
                          {!subscriptionDetails?.cancel_at_period_end && (
                            <PauseSub
                              handleChangeRefresh={handleChangeRefresh}
                              handleChange={handleChange}
                              subscriptionDetails={subscriptionDetails}
                            />
                          )}
                        </div>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    {configData?.planpage && (
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box>
                            <Typography variant="h1">
                              Account Credits
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 600,
                                fontStyle: "normal",
                                textTransform: "none",
                              }}
                            >
                              Available balance : $
                              {-customerDetails?.balance / 100}{" "}
                              {customerDetails?.currency?.toUpperCase()}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              py: 1,
                              display: "flex",
                              flexDirection: "row",
                              gap: 0.5,
                            }}
                          >
                            <TextField
                              onChange={(e: any) => setCode(e.target.value)}
                              size="small"
                              value={code}
                              placeholder="Apply Gift Card"
                            />
                            <div className="value">
                              <button
                                onClick={handleClickCode}
                                style={{ width: "auto", fontSize: "16px" }}
                                className={configData.buttonCommonClass}
                              >
                                Apply
                              </button>
                            </div>
                          </Box>
                          {subscriptionDetails?.id && (
                            <Box
                              sx={{
                                py: 1,
                                display: "flex",
                                flexDirection: "row",
                                gap: 0.5,
                              }}
                            >
                              <TextField
                                onChange={(e: any) =>
                                  setCoupenCode(e.target.value)
                                }
                                size="small"
                                value={coupenCode}
                                placeholder="Apply Coupon Code"
                              />
                              <div className="value">
                                <button
                                  onClick={handleApplyCode}
                                  style={{ width: "auto", fontSize: "16px" }}
                                  className={configData.buttonCommonClass}
                                >
                                  Apply
                                </button>
                              </div>
                            </Box>
                          )}
                        </Box>
                        {userDownloadCredit &&
                          currentPlan?.PlanTypeID == INSIDER_PLAN_ID && (
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box>
                                <Typography variant="h1">
                                  Download Limits
                                </Typography>

                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 600,
                                    fontStyle: "normal",
                                    textTransform: "none",
                                  }}
                                >
                                  Clips: {userDownloadCredit?.ClipsCredit}{" "}
                                  Download Credit
                                  {userDownloadCredit?.ClipsCredit !== 1 &&
                                    "s"}{" "}
                                  Left
                                </Typography>
                                <Alert
                                  sx={{ my: 2, whiteSpace: "normal" }}
                                  severity="info"
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      width: "260px",
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      fontStyle: "normal",
                                      textTransform: "none",
                                    }}
                                  >
                                    Download tokens expire at the end of each
                                    billing period and cannot be carried over to
                                    the next billing period.
                                  </Typography>
                                </Alert>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                  {subscriptionDetails && currentPlan?.PlanTypeID && (
                    <Box>
                      <Box>
                        <Typography variant="h1">Payment Method</Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            textTransform: "none",
                          }}
                        >
                          Your current payment method is below
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          mt: 2,
                          border: "1px solid #DADADA",
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 500 }}
                        >
                          {userData?.Email}
                        </Typography>
                        {currentPaymentDetials?.type == "card" ? (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 600 }}
                          >
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <img
                              width={"40px"}
                              style={{ marginRight: "8px" }}
                              src={showPatmentIcon(
                                currentPaymentDetials?.card?.brand
                              )}
                            />
                            Expires {currentPaymentDetials?.card?.exp_month}/
                            {currentPaymentDetials?.card?.exp_year}
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: 600 }}
                          >
                            {currentPaymentDetials?.type}
                          </Typography>
                        )}
                        <div className="value">
                          <button
                            onClick={handleClickOpen}
                            style={{ width: "auto", fontSize: "16px" }}
                            className={configData.buttonCommonClass}
                          >
                            Change
                          </button>
                        </div>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{ fontWeight: 600 }}
                              >
                                Update Payment Details
                              </Typography>

                              <IconButton onClick={handleClose}>
                                <Close />
                              </IconButton>
                            </Box>
                          </DialogTitle>
                          <PaymentUpdate
                            handleChangeRefresh={handleChangeRefresh}
                            handleClose={() => handleClose()}
                            customerId={customerDetails?.id}
                            subscriptionId={subscriptionDetails?.id}
                          />
                        </Dialog>
                      </Box>
                    </Box>
                  )}
                </Box>
              </div>
            </div>
          </div>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Payment Invoices</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Watch</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentHistory
                    ?.sort(
                      (a: any, b: any) =>
                        b?.payment?.created - a?.payment?.created
                    )
                    ?.map((row: any, index: any) => (
                      <TableRow
                        key={index + 1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <DownloadPdfComponent row={row} />
                        </TableCell>
                        <TableCell align="left">
                          {row?.payment?.description
                            ? row?.payment?.description
                            : row?.payment?.billing_reason ==
                              "subscription_create"
                            ? "Subscription creation"
                            : row?.payment?.billing_reason ==
                              "subscription_update"
                            ? "Subscription update"
                            : "One time pass creation"}
                        </TableCell>{" "}
                        <TableCell align="left">
                          ${(row?.payment?.amount / 100).toFixed(2)}
                        </TableCell>
                        <TableCell align="left">
                          {moment
                            .unix(row?.payment?.created)
                            .format("MMMM DD , YYYY hh:mm A")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.payment?.status}
                        </TableCell>
                        <TableCell align="left">
                          {row?.payment?.description == "Gift Card Purchase" ? (
                            <></>
                          ) : (
                            <button
                              onClick={() => {
                                if (row?.invoice?.metadata?.PlanTypeID == "3") {
                                  navigate(
                                    PageRoutes.Watch.replace(
                                      ":gameId",
                                      row?.invoice?.metadata?.GameID
                                    )
                                  );
                                } else {
                                  navigate(PageRoutes.UpcomingEvent);
                                }
                              }}
                              className={configData.buttonCommonClass}
                            >
                              Watch
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <div className="col-sm-9 text-left" style={{ margin: "auto" }}>
          <section>
            <div className="mt-5 mb-5 text-center">
              <h3 className="fst-italic fs-3 text-uppercase">
                You do not have purchased events yet
              </h3>
            </div>
          </section>
          <div onClick={() => navigate(PageRoutes.UpcomingEvent)}>
            <button
              className={`w-100 text-uppercase ${configData.buttonCommonClass}`}
            >
              Watch upcoming events
            </button>
          </div>
        </div>
      )}
    </MyAccount>
  );
};
export default PaymentInformation;
