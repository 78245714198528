import { createContext, useEffect, useRef, useState } from "react";
import HttpService from "../../hooks/Https-services";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import { APIRoutes } from "../../utils/constants";
import { useLocation, useParams } from "react-router-dom";
import React from "react";

import useConfig from "../../hooks/useConfig";

import { db } from "../../utils/firebase";
import {
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
} from "firebase/database";
const LoadingContext: any = createContext(null);

export const LoadingContextProvider = ({ children }: any) => {
  const [isLoadingPayment, setLoadingPaymet] = useState(false);
  const [openColleps, setOpenColleps] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState("");
  const [receivedImage, setReceivedImage] = useState("");
  const configData = useConfig();

  const handleChange = (val: any) => {
    setLoadingPaymet(val);
  };
  const handleChangeColleps = () => {
    setOpenColleps(true);
  };
  const CloseColleps = () => {
    setOpenColleps(false);
  };
  const location = useLocation();
  const [logsCount, setLogsCount] = useState<any>(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isCounted, setIsCounted] = useState(false);
  const [errorStyle, setErrorStyle] = useState<any>({});
  const auth = getCurrentAuth();
  const [gameId, setGameId] = useState();
  const GetLogsCount = async (signal?: AbortSignal) => {
    try {
      const res = await HttpService.get(APIRoutes.GetLogsCount, signal);
      if (res) {
        setLogsCount(res.data.data.total_entries);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const controller = new AbortController();
    const messagesRef = ref(db, `${configData.name}/video-player-errors`);
    const q = query(messagesRef, orderByChild("timestamp"), limitToLast(1));
    const messagesRefAnnocement = ref(
      db,
      `${configData.name}/admin-announcement`
    );
    const qAn = query(
      messagesRefAnnocement,
      orderByChild("timestamp"),
      limitToLast(1)
    );

    onValue(q, (snapshot) => {
      let lastMessage: any = null;
      snapshot.forEach((childSnapshot) => {
        lastMessage = childSnapshot.val();
      });

      if (
        lastMessage?.customer_id == configData.customer &&
        lastMessage?.game_id == gameId
      ) {
        if (lastMessage?.open && lastMessage?.is_active) {
          handleChangeColleps();
          setErrorStyle({
            Placement: lastMessage?.placement,
            Speed: lastMessage?.speed,
            Type: lastMessage?.type,
            Time: lastMessage?.time,
            Link: lastMessage?.link,
            Linking: lastMessage?.linking,
            IsPause: lastMessage?.is_pause,
          });
          setReceivedMessage(lastMessage?.message);
          setReceivedImage(lastMessage?.image);
        } else {
          CloseColleps();
        }
      }
    });

    onValue(qAn, (snapshot) => {
      let lastMessage: any = null;
      snapshot.forEach((childSnapshot) => {
        lastMessage = childSnapshot.val();
      });
      if (auth?.AccessToken) {
        setTimeout(() => {
          GetLogsCount(controller.signal);
        }, 1000);
      }
    });
    setIsCounted(true);
    return () => {
      controller.abort();
    };
    // Cleanup subscription on unmount
  }, [gameId, isRefresh]);
  return (
    <LoadingContext.Provider
      value={
        {
          handleChange,
          isLoadingPayment,
          logsCount,
          errorStyle,
          setErrorStyle,
          receivedMessage,
          openColleps,
          GetLogsCount,
          setReceivedMessage,
          setReceivedImage,
          receivedImage,
          handleChangeColleps,
          CloseColleps,
          setIsRefresh,
          setGameId,
          isRefresh,
        } as any
      }
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
