import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import { Button, Typography } from "@mui/material";
import momentTimezone from "moment-timezone";
import "./index.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SensorsIcon from "@mui/icons-material/Sensors";
import FolderIcon from "@mui/icons-material/Folder";
import React from "react";
import useConfig from "../../hooks/useConfig";
import HttpService from "../../hooks/Https-services";
import PassesMain from "../Passes/Passes";
import GameListHomePage from "./GameListHomePage";
import moment from "moment";
const userTimeZone = momentTimezone.tz.guess();

const HomePage: FC = () => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [sliedRefHeader, setSlideRefHeader] = useState<any>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [teamEventsList, setTeamEventsList] = useState<any>([]);
  const [teamEventsListVOD, setTeamEventsListVOD] = useState<any>([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const [slider, setSlider] = useState([]);
  const auth = getCurrentAuth();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);

  const configData = useConfig();

  const sliderSettingsHeader = {
    // removes default buttons
    arrows: false,
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const CheckUserSubscribe = async () => {
    try {
      const res = await HttpService.get(APIRoutes.CheckSubscribe);
      if (res?.data?.data?.isSubscribe) {
        setIsSubscribed(true);
      }
    } catch (error) {}
  };
  const GetUserSubscriptionData = async () => {
    if (!auth.AccessToken) {
      return;
    }
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetails(res?.data?.data?.subscription);
        return res?.data?.data;
      }
    } catch (error) {}
  };
  function isCurrentTimeMoreThanTenMinutesBefore(givenTimeStr: any) {
    // Parse the given time in the specified timezone

    const givenTime = momentTimezone
      .tz(givenTimeStr, "YYYY-MM-DD HH:mm:ss.SSSSSS A Z", "America/New_York")
      .tz(userTimeZone)
      .clone()
      .subtract(10, "minutes");

    const currentTime = momentTimezone.tz(userTimeZone);

    return currentTime.isAfter(givenTime);
  }
  const FetchTeamEvents = async () => {
    try {
      // setIsLoading(true);
      const params: any = {
        customer: configData.customer,
        status: "live",
        page: 1,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });
      setTeamEventsList(result.data.data);
    } catch (error) {
    } finally {
      // setIsLoading(false);
    }
  };
  const FetchTeamEventsVOD = async () => {
    try {
      // setIsLoading(true);
      const params: any = {
        customer: configData.customer,
        status: "vod",
        page: 1,
      };

      const result = await HttpService.getapiParams(APIRoutes.TeamEventsList, {
        params,
      });
      setTeamEventsListVOD(result.data.data);
    } catch (error) {
    } finally {
      // setIsLoading(false);
    }
  };
  const GetSliderImages = async () => {
    try {
      // setIsLoading(true);

      const result = await HttpService.get(APIRoutes.Slider);

      setSlider(
        result?.data?.data?.sort(
          (a: any, b: any) => parseInt(a.Priority) - parseInt(b.Priority)
        )
      );
    } catch (error) {
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth.AccessToken) {
      CheckUserSubscribe();
      GetUserSubscriptionData();
    }

    FetchTeamEvents();
    FetchTeamEventsVOD();
    GetSliderImages();
  }, []);

  const handleWatch = (id: any, type: string) => {
    if (type === "channel" || !type) {
      navigate(`${PageRoutes.UpcomingEvent}?channel_id=${id}`);
    } else {
      navigate(`${PageRoutes.Watch?.replace(":gameId", id?.game_id)}`);
    }
  };

  return (
    <Box>
      <div style={{ margin: "auto", marginBottom: "60px" }}>
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <Slider ref={setSlideRefHeader} {...sliderSettingsHeader}>
            {slider?.map((el: any, index: any) => (
              <Box
                sx={{
                  backgroundImage: `url(${el?.Image})`,
                  backgroundSize: "cover",
                }}
                className={`carousel-item`}
              >
                <div
                  className="carousel-content"
                  style={{
                    gap: 8,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: el?.FontColor,
                    background: `linear-gradient(to right, ${el?.FontColor} 0%, ${el?.FontColor} 60%, transparent 100%)`,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: 0.65,
                      display: { xs: "none", sm: "block" },
                      textTransform: "none",
                      ">p": { fontStyle: "normal", textTransform: "none" },
                    }}
                    dangerouslySetInnerHTML={{ __html: el?.DisplayText }}
                  ></Box>

                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      textTransform: "none",
                      ">p": { fontStyle: "normal", textTransform: "none" },
                    }}
                    dangerouslySetInnerHTML={{ __html: el?.MobileText }}
                  ></Box>

                  <Button
                    sx={{
                      display: { xs: "none", md: "flex" },
                    }}
                    variant="contained"
                    size="medium"
                    onClick={() =>
                      handleWatch(JSON.parse(el?.WatchUrl), el?.Type)
                    }
                  >
                    WATCH
                  </Button>
                  <Button
                    sx={{
                      display: { xs: "flex", sm: "none" },
                    }}
                    variant="contained"
                    size="small"
                    onClick={() =>
                      handleWatch(JSON.parse(el?.WatchUrl), el?.Type)
                    }
                  >
                    WATCH
                  </Button>
                </div>
              </Box>
            ))}
          </Slider>
        </div>
      </div>

      <Box
        sx={{ px: 2, py: 5, display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Box>
          <GameListHomePage
            subtitle={"Live"}
            gameStatus={
              <Box sx={{ display: "flex", gap: 1 }}>
                <SensorsIcon
                  sx={{
                    color: "#fff",
                  }}
                />
              </Box>
            }
            title={"Currently Live"}
            games={teamEventsList?.filter((el: any) =>
              isCurrentTimeMoreThanTenMinutesBefore(el.game_date_time)
            )}
            subscriptionDetails={subscriptionDetails}
            auth={auth}
            isSubscribed={isSubscribed}
          />
        </Box>

        <Box>
          <GameListHomePage
            gameStatus={
              <Box sx={{ display: "flex", gap: 1 }}>
                <CalendarMonthIcon
                  sx={{
                    color: "#fff",
                  }}
                />
              </Box>
            }
            subtitle={"Upcoming"}
            title={"Upcoming"}
            games={teamEventsList}
            subscriptionDetails={subscriptionDetails}
            auth={auth}
            isSubscribed={isSubscribed}
          />
        </Box>

        {!!teamEventsListVOD?.length && (
          <Box>
            <GameListHomePage
              gameStatus={
                <Box sx={{ display: "flex", gap: 1 }}>
                  <FolderIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                </Box>
              }
              subtitle={"Completed"}
              title={"Recently Completed"}
              games={teamEventsListVOD}
              subscriptionDetails={subscriptionDetails}
              auth={auth}
              isSubscribed={isSubscribed}
            />
          </Box>
        )}
      </Box>

      {configData?.planpage && (
        <Box sx={{ maxWidth: "1110px", m: "auto", mt: 2 }}>
          <br />
          <Box>
            <PassesMain isHomePage={true} />
          </Box>
          {/* <Passes isHomePage={true} /> */}
        </Box>
      )}
    </Box>
  );
};
export default HomePage;
