import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "..";
import EllipsisContent from "../../../Common/Component/EllipsisContent";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import { APIRoutes } from "../../../utils/constants";

const SeasonCard = () => {
  const [isChecked, setIsChecked] = useState(-1);
  const {
    channel,
    selectAllTeamPlayers,
    setSelectAllTeamPlayers,
    selectedPlayer,
    selectedSeason,
    setSelectedSeason,
    channelData,
    selectedEvent,
    selectedTeam,
  }: any = useContext(UserContext);
  const [seasons, setSeasons] = useState([]);
  const [isSkeleton, setIsSkeleton] = useState(true);

  // const handleCheckboxClick = () => {
  //   setIsChecked(!isChecked);
  // };
  const { channel_id }: any = useParams();
  const updateChannel = async (season: any) => {
    const updateConfigure = {
      Name: channelData,
      Teams: selectedTeam,
      Players: selectedPlayer,
      Events: selectedEvent,
      All: selectAllTeamPlayers,
      Seasons: season,
    };

    try {
      const res = await HttpService.post(
        APIRoutes.UpdateChannel.replace(":id", channel_id),
        {
          configure: updateConfigure,
        }
      );
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const GetAllTeams = async () => {
    try {
      const res = await HttpService.getapi(APIRoutes.GetSeasons);
      if (res) {
        setSeasons(res.data.data);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    GetAllTeams();
  }, []);

  return (
    <>
      {isSkeleton ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", py: 10, width: 1 }}
        >
          <CircularProgress
            size={48}
            sx={{
              color: "#bf311a",
            }}
          />
        </Box>
      ) : seasons?.length != 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
          {seasons.map((season: any, index) => (
            <Box sx={{ borderBottom: "1px solid #dadada" }} key={index + 1}>
              <Box
                sx={{
                  padding: 1,
                  textAlign: "center",
                  position: "relative",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxHeight: "100px",
                }}
              >
                <EllipsisContent text={season?.season_name} />
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      updateChannel([...selectedSeason, season]);
                      setSelectedSeason([...selectedSeason, season]);
                    } else {
                      const index = selectedSeason.findIndex(
                        (element: any) =>
                          element?.season_id == season?.season_id
                      );
                      if (index != -1) {
                        const seasonData = selectedSeason.filter(
                          (e: any, ind: any) => ind != index
                        );

                        updateChannel(seasonData);
                        setSelectedSeason(seasonData);
                      }
                    }
                  }}
                  checked={
                    selectedSeason?.find(
                      (el: any) => el?.season_id === season?.season_id
                    )
                      ? true
                      : false
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography sx={{ my: 2, mx: "auto" }}>No data found</Typography>
      )}
    </>
  );
};

export default SeasonCard;
