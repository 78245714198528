import {
  VisibilityOff,
  Visibility,
  AccountCircle,
  Key,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";
import useAxiosPrivate, { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import useConfig from "../../hooks/useConfig";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";

const Register = () => {
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSKeleton, setIsSkeleton] = useState(false);
  const navigate = useNavigate();
  const [ips, setIp] = useState("0.0.0.0");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  } as any);
  const getIp = async () => {
    try {
      const ip: any = await fetch("https://api.ipify.org?format=json");
      const ip2 = await ip.json();
      setIp(ip2.ip);
    } catch (error) {
      setIp("0.0.0.0");
    }
  };
  const configData = useConfig();
  const handleClickShowPassword = (flag: any) => {
    const item = { ...showPassword };
    item[flag] = !showPassword[flag];

    setShowPassword(item);
  };
  useEffect(() => {
    getIp();
  }, []);
  const validate = (values: any) => {
    const errors: any = {};

    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const passwordRegx = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );
    if (!values.email) {
      errors.email = "Please fill the required fields";
    } else if (!emailRegx.test(values.email)) {
      errors.email = "Please enter valid email id";
    }

    if (!values.password) {
      errors.password = "Please fill the required fields";
    } else if (!passwordRegx.test(values.password)) {
      errors.password =
        "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
    }

    if (!values.first_name) {
      errors.first_name = "Please fill the required fields";
    }
    if (!values.last_name) {
      errors.last_name = "Please fill the required fields";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Please fill the required fields";
    }
    if (!values.term) {
      errors.term = "Please accept term and condition";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      password: "",
      email: "",
      confirm_password: "",
      news: false,
      term: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }
      setIsSkeleton(true);

      try {
        const data = {
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name,
          news: values.news,
          email: values.email,
          ip_address: ips || null,
        };
        const res = await HttpService.post(APIRoutes.Register, data);
        if (res) {
          toast.success("Verfication email has been sent");
        }
        navigate(PageRoutes.Login);
        setIsSkeleton(false);
      } catch (error: any) {
        setIsSkeleton(false);
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const auth = getCurrentAuth();
  const location = useLocation();

  if (auth.AccessToken) {
    return (
      <Navigate to={PageRoutes.HomePage} state={{ from: location }} replace />
    );
  }
  return (
    <section className="main-content vh-100">
      <div className="container h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div data-v-202744ad="" className="col-md-8">
            <div data-v-202744ad="" className="card">
              <Typography
                sx={{ fontStyle: "normal", m: "auto", width: 1, py: 2 }}
                variant="h1"
                className="card-header"
              >
                {" "}
                <Box>
                  <img
                    src={configData?.logoUrl}
                    style={{ maxHeight: "70px" }}
                    alt="shape"
                  />
                </Box>
                Register
              </Typography>
              <div data-v-202744ad="" className="card-body">
                <form
                  data-v-202744ad=""
                  onSubmit={formik.handleSubmit}
                  className="d-flex flex-column gap-3"
                >
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-ee71dd48=""
                      data-v-202744ad=""
                      className="form form-group col-md-6"
                    >
                      <TextField
                        size="small"
                        data-v-ee71dd48=""
                        type="text"
                        name="first_name"
                        id="first_name"
                        error={formik.errors.first_name ? true : false}
                        helperText={formik.errors.first_name}
                        placeholder="First Name"
                        value={formik.values.first_name}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.first_name = "";
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-ee71dd48=""
                      data-v-202744ad=""
                      className="form form-group col-md-6"
                    >
                      <TextField
                        size="small"
                        data-v-ee71dd48=""
                        type="text"
                        name="last_name"
                        error={formik.errors.last_name ? true : false}
                        helperText={formik.errors.last_name}
                        id="last_name"
                        placeholder="Last Name"
                        value={formik.values.last_name}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.last_name = "";
                        }}
                        className="form-control"
                      />
                      <span data-v-ee71dd48="" className="field-icon">
                        <img
                          data-v-ee71dd48=""
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAiJJREFUOI191E+IV1UUwPHP/Fz0xyDpjw1Fm5IRS0smSMS7aOMijEiGLuqARLURdFAz1Hqk0KXETTGkxmCbFgO+YEBaNDiiI75NOdRmlhUik0KRgUzMyDj8XLw7w2P6TXdz73n3nO/58865Xe1223KrKKsV2ISt2ICHMIUruJxiuLWsMbo6wYuy6sUA+vDIMrZt/IxvMZRimP1feFFWT+AU3sZdPIhf8TV+wAzWYnfWmccDOZuDKYbvOsKLslqP79GdQdtxGgMphvkO2b2KUfyJafTiJI6mGNrQyorPYwxdCHgFPy4HhhTDT3g3Z3IGgziMzxcj//jc1Ycz6ElswTPqH9aXYhhpRHkWT6HMTtv5bhK3Ugxbi7L6AvvRn2IYbuEDvIgdKYbf8HJ2PN4I9Ky6W1ZjL95q3I1jYz4fyvJgUVaPtrAZtzGRFVbm/d8G4NklVWnK0ws2uYQX8Th6WvgEq/BlVr6Z9zUNwDeN8z8Yacg9+AOKsnoBH2EkxXCtlWKYwKd4ryirz3BJ3cP9DcCHeBP78FKKYSrDVqsHbKwoqx5cyM73kFuxKKsuDOF9nFf37+vYkmL4RYeVbc6p638ExzCH11IMk4vwhvIBnMBsdtDGrhTD6BLwY/gKO/E7nsM1xBTD9QW9/4x/UVbr1MPwRuPzHdxQT2g3nsaKfPcXjqufgHtNVse3JTvpwTa8kyNbqR6yOfyt7ophjKcYZjox7gO7RMC15nhDegAAAABJRU5ErkJggg=="
                          alt=""
                          className="toggle-password-visibility-icon"
                          style={{ display: "none" }}
                        />
                      </span>
                      <small
                        data-v-ee71dd48=""
                        className="text-danger"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>

                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-ee71dd48=""
                      data-v-202744ad=""
                      className="form form-group col-md-6"
                    >
                      <TextField
                        size="small"
                        data-v-ee71dd48=""
                        type="text"
                        autoComplete="username"
                        placeholder="Email"
                        name="email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                        error={formik.errors.email ? true : false}
                        helperText={formik.errors.email}
                        id="email"
                        value={formik.values.email}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.email = "";
                        }}
                        className="form-control"
                      />
                      <span data-v-ee71dd48="" className="field-icon">
                        <img
                          data-v-ee71dd48=""
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAOCAYAAADE84fzAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAiJJREFUOI191E+IV1UUwPHP/Fz0xyDpjw1Fm5IRS0smSMS7aOMijEiGLuqARLURdFAz1Hqk0KXETTGkxmCbFgO+YEBaNDiiI75NOdRmlhUik0KRgUzMyDj8XLw7w2P6TXdz73n3nO/58865Xe1223KrKKsV2ISt2ICHMIUruJxiuLWsMbo6wYuy6sUA+vDIMrZt/IxvMZRimP1feFFWT+AU3sZdPIhf8TV+wAzWYnfWmccDOZuDKYbvOsKLslqP79GdQdtxGgMphvkO2b2KUfyJafTiJI6mGNrQyorPYwxdCHgFPy4HhhTDT3g3Z3IGgziMzxcj//jc1Ycz6ElswTPqH9aXYhhpRHkWT6HMTtv5bhK3Ugxbi7L6AvvRn2IYbuEDvIgdKYbf8HJ2PN4I9Ky6W1ZjL95q3I1jYz4fyvJgUVaPtrAZtzGRFVbm/d8G4NklVWnK0ws2uYQX8Th6WvgEq/BlVr6Z9zUNwDeN8z8Yacg9+AOKsnoBH2EkxXCtlWKYwKd4ryirz3BJ3cP9DcCHeBP78FKKYSrDVqsHbKwoqx5cyM73kFuxKKsuDOF9nFf37+vYkmL4RYeVbc6p638ExzCH11IMk4vwhvIBnMBsdtDGrhTD6BLwY/gKO/E7nsM1xBTD9QW9/4x/UVbr1MPwRuPzHdxQT2g3nsaKfPcXjqufgHtNVse3JTvpwTa8kyNbqR6yOfyt7ophjKcYZjox7gO7RMC15nhDegAAAABJRU5ErkJggg=="
                          alt=""
                          className="toggle-password-visibility-icon"
                          style={{ display: "none" }}
                        />
                      </span>
                      <small
                        data-v-ee71dd48=""
                        className="text-danger"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-ee71dd48=""
                      data-v-202744ad=""
                      className="form form-group col-md-6"
                    >
                      <OutlinedInput
                        size="small"
                        data-v-ee71dd48=""
                        type={showPassword.password ? "text" : "password"}
                        name="password"
                        id="password"
                        value={formik.values.password}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.password = "";
                        }}
                        error={formik.errors.password ? true : false}
                        placeholder="Password"
                        sx={{ py: 0 }}
                        autoComplete="new-password"
                        startAdornment={
                          <InputAdornment position="start">
                            <Key />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword.password ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        className="form-control"
                      />
                      <FormHelperText sx={{ color: "#d50000", px: 2 }}>
                        {" "}
                        {formik.errors.password}
                      </FormHelperText>
                    </div>
                  </div>
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-ee71dd48=""
                      data-v-202744ad=""
                      className="form form-group col-md-6"
                    >
                      <TextField
                        size="small"
                        data-v-ee71dd48=""
                        type={
                          showPassword.confirm_password ? "text" : "password"
                        }
                        name="confirm_password"
                        id="confirm_password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    handleClickShowPassword("confirm_password")
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword.confirm_password ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.confirm_password = "";
                          setConfirmPasswordError("");
                        }}
                        onBlur={(e) => {
                          if (formik.values.password) {
                            if (formik.values.password != e.target.value) {
                              setConfirmPasswordError(
                                "Confirm password do not match with entered password"
                              );
                            } else {
                              setConfirmPasswordError("");
                            }
                          }
                        }}
                        error={
                          formik.errors.confirm_password || confirmPasswordError
                            ? true
                            : false
                        }
                        helperText={
                          formik.errors.confirm_password || confirmPasswordError
                        }
                        value={formik.values.confirm_password}
                        placeholder="Confirm Password"
                        autoComplete="current-password"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center my-3"
                  >
                    <div
                      data-v-e39a7f16=""
                      data-v-202744ad=""
                      className="col-md-6 text-left"
                    >
                      <div
                        data-v-2e0bf771=""
                        data-v-e39a7f16=""
                        className="d-flex align-items-start"
                      >
                        <Checkbox
                          data-v-2e0bf771=""
                          id="term"
                          value={formik.values.term}
                          sx={{ p: 0 }}
                          aria-label="k"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.errors.term = "";
                          }}
                          defaultValue={1}
                          name="term"
                        />
                        <label
                          className="mx-1"
                          data-v-2e0bf771=""
                          htmlFor="term"
                        >
                          <p
                            data-v-e39a7f16=""
                            style={{ fontStyle: "normal" }}
                            data-v-2e0bf771=""
                          >
                            {" "}
                            I Accept the{" "}
                            <a
                              href={PageRoutes.PrivacyPolicy}
                              data-v-e39a7f16=""
                              target="_blank"
                              style={{ color: "#D11D1D", cursor: "pointer" }}
                              data-v-2e0bf771=""
                            >
                              terms &amp; conditions
                            </a>{" "}
                            and{" "}
                            <a
                              href={PageRoutes.PrivacyPolicy}
                              target="_blank"
                              data-v-e39a7f16=""
                              style={{ color: "#D11D1D", cursor: "pointer" }}
                              data-v-2e0bf771=""
                            >
                              privacy policy
                            </a>{" "}
                            *{" "}
                          </p>
                          {/**/}
                        </label>
                      </div>
                      <FormHelperText sx={{ color: "#d50000", px: 2 }}>
                        {" "}
                        {formik.errors.term}
                      </FormHelperText>
                      {/**/}
                    </div>
                  </div>
                  <div
                    data-v-202744ad=""
                    className="row justify-content-center"
                  >
                    <div
                      data-v-29d2388d=""
                      data-v-202744ad=""
                      className="col-md-6 text-left"
                    >
                      <div
                        data-v-2e0bf771=""
                        data-v-29d2388d=""
                        className="d-flex align-items-start"
                      >
                        <Checkbox
                          data-v-2e0bf771=""
                          id="news"
                          sx={{ p: 0 }}
                          aria-label="k"
                          defaultValue={1}
                          name="news"
                          value={formik.values.news}
                          onChange={formik.handleChange}
                        />
                        <label
                          data-v-2e0bf771=""
                          htmlFor="news"
                          className="mx-1"
                        >
                          <p
                            style={{ fontStyle: "normal" }}
                            data-v-29d2388d=""
                            data-v-2e0bf771=""
                          >
                            {" "}
                            Yes, I want to receive news and updates from{" "}
                            {configData.subtitle}{" "}
                          </p>
                          {/**/}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    data-v-202744ad=""
                    className=" d-flex justify-content-center"
                  >
                    <div data-v-496281e4="" data-v-202744ad="">
                      <button
                        className={configData.buttonCommonClass}
                        disabled={isSKeleton}
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                  {/**/}
                  <Box
                    data-v-202744ad=""
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: 1,
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontStyle: "normal" }} data-v-202744ad="">
                      {" "}
                      If you have already registered, please{" "}
                    </p>
                    <Button
                      variant="text"
                      data-v-202744ad=""
                      href="/login"
                      sx={{ fontStyle: "normal", fontSize: "14px" }}
                    >
                      click here to login
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Register;
