import { Box, Typography, Divider, Checkbox } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../..";
import PlayerCards from "../PlayerCards";
import EllipsisContent from "../../../../../Common/Component/EllipsisContent";

const TeamWithPlayer = ({ teamname }: any) => {
  const {
    selectedTeam,
    setSelectedPlayer,
    setSelectedTeam,
    selectedPlayer,
    isSelectAllPlayer,
    setIsSelectAllPlayers,
    isSelectAllEvent,
    setIsSelectAllEvents,
    selectAllTeamPlayers,
    setSelectAllTeamPlayers,
    channel,
  }: any = useContext(UserContext);

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [selectAllPlayer, setSelectAllPlayer] = useState(false);

  useEffect(() => {
    const filtered = selectedPlayer.filter((item: any) =>
      teamname.players?.includes(item)
    );

    if (filtered?.length == 0) {
      setSelectAllPlayer(true);
      const arrayTobeSubmit = [...selectAllTeamPlayers, teamname.teamname];

      const uniqueNames = arrayTobeSubmit.filter(
        (val: any, id: any, array: any) => {
          return array.indexOf(val) == id;
        }
      );

      setSelectAllTeamPlayers(uniqueNames);
    } else {
      setSelectAllPlayer(false);
    }
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <EllipsisContent
          text={teamname.teamname}
          fontWeight="700"
          sx={{ textTransform: "none", fontStyle: "normal" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "sticky",
            bgcolor: "white",
            top: -8,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
              whiteSpace: "nowrap",
            }}
          >
            Select All
          </Box>
          <Checkbox
            checked={
              selectAllPlayer ||
              selectAllTeamPlayers.includes(teamname.teamname)
            }
            onChange={(e) => {
              if (e.target.checked) {
                const filtered = selectedPlayer.filter(
                  (item: any) => !teamname.players?.includes(item)
                );

                if (filtered?.length == 0) {
                  setSelectAllPlayer(true);
                } else {
                  setSelectAllPlayer(false);
                }
                const arrayTobeSubmit = [
                  ...selectAllTeamPlayers,
                  teamname.teamname,
                ];

                const uniqueNames = arrayTobeSubmit.filter(
                  (val: any, id: any, array: any) => {
                    return array.indexOf(val) == id;
                  }
                );

                setSelectAllTeamPlayers(uniqueNames);
                setSelectedPlayer(filtered);

                setSelectAllPlayer(true);
              } else {
                const removeTeam = selectAllTeamPlayers?.filter(
                  (el: any) => el != teamname.teamname
                );
                setSelectAllTeamPlayers(removeTeam);
                setSelectAllPlayer(false);
              }
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ borderColor: "black", my: 0.5 }} />
      <Box
        maxHeight="200px"
        sx={{
          display: selectAllPlayer ? "none" : "block",
          overflowY: "scroll",
        }}
      >
        <PlayerCards playersName={teamname.players} />
      </Box>
    </Box>
  );
};
export default TeamWithPlayer;
