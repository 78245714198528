import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import AppleLogin from "react-apple-login";
import "./index.css";
import {
  AccountCircle,
  Key,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useFormik } from "formik";
import { commonDetailSlice } from "../../store/reducers/commonReducer";
import { useDispatch } from "react-redux";
import useAxiosPrivate, { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { setAuth } from "../../hooks/useRefreshToken";
import { toast } from "react-toastify";
import React from "react";
import useConfig from "../../hooks/useConfig";
import DialogBox from "../../Common/Component/DialogBox";
import HttpService from "../../hooks/Https-services";
import LoadingContext from "../../Common/Contex/LoadingContex";

const Login: FC = () => {
  const [flagLogin, setFlagLogin] = useState(true);
  const [serachParams, setSearchParams] = useSearchParams();
  const [isSKeleton, setIsSkeleton] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [ips, setIp] = useState("0.0.0.0");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleResend = async () => {
    try {
      const password = formik.values.password;
      const email = formik.values.email;
      const dataBody = {
        email: email,
        password: password,
      };
      const res: any = await HttpService.post(APIRoutes.ResendInvite, dataBody);
      setFlagLogin(true);
      toast.success("Verfication email has been sent");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const responseGoogle = (response: any) => {
    console.log(response);
  };
  const formikRef = useRef<any>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { isRefresh, setIsRefresh }: any = React.useContext(LoadingContext);

  async function getEntries(data: any) {
    data.isChannelCreated = false;
    try {
      const res = await HttpService.get(APIRoutes.GetAllChannel);
      if (res.data.data == null || res.data.data.length == 0) {
        data.isChannelCreated = false;
      } else {
        data.isChannelCreated = true;
      }
    } catch (error) {}

    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
    setIsRefresh(!isRefresh);
  }
  const responseApple = (response: any) => {
    console.log(response);
  };
  const responseFacebook = (response: any) => {
    console.log(response);
  };
  const intialValue: {
    [key: string]: string;
  } = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const validate = (values: any) => {
    const errors: any = {};
    const flag = serachParams.get("q");
    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const passwordRegx = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`=+ -])(?=.{8,})[^\s]+$/
    );
    if (!values.email) {
      errors.email = "Please fill the required fields";
    } else if (!emailRegx.test(values.email)) {
      errors.email = "Please enter valid email id";
    }

    if (!values.password) {
      errors.password = "Please fill the required fields";
    }
    return errors;
  };
  const getIp = async () => {
    try {
      const ip: any = await fetch("https://api.ipify.org?format=json");
      const ip2 = await ip.json();
      setIp(ip2.ip);
    } catch (error) {
      setIp("0.0.0.0");
    }
  };
  useEffect(() => {
    if (serachParams.get("user")) {
      handleClickOpen();
      serachParams.delete("user");
      setSearchParams(serachParams);
    }
    getIp();
  }, []);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!flagLogin) {
        return;
      }
      if (confirmPasswordError) {
        return;
      }
      setIsSkeleton(true);
      try {
        const password = values.password;
        const email = values.email;

        const dataBody: any = {
          password,
          email,
          ip_address: ips,
        };
        const res: any = await HttpService.post(APIRoutes.Login, dataBody);
        if (res.data) {
          if (res.data.data?.Type == "USER_IMPORTED") {
            navigate({
              pathname: PageRoutes.PasswordReset,
              search: `token=${res.data.data?.Token}&content=${btoa(
                res.data.data?.Information
              )}`,
            });
          } else {
            toast.success("Login successful");
            setAuth(res.data.data);
            setTimeout(() => getEntries(res.data.data), 100);
            location.state.from
              ? navigate(`${location?.state?.from}${location.state.search}`)
              : navigate(PageRoutes.HomePage);
          }
        }
        setIsSkeleton(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
        if (error.response.data.responseType == "EMAIL_NOT_VERIFIED") {
          setFlagLogin(false);
        }

        // formik.resetForm();
        setIsSkeleton(false);
      }
    },
  });
  const auth = getCurrentAuth();
  const configData = useConfig();

  if (auth.AccessToken) {
    return (
      <Navigate
        to={
          location?.state?.from
            ? `${location?.state?.from}${location.state.search}`
            : PageRoutes.HomePage
        }
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  return (
    <section className="main-content vh-100">
      <div className="container h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-7">
            <div className="card">
              {flagLogin ? (
                <Typography
                  sx={{ fontStyle: "normal", m: "auto", width: 1, py: 2 }}
                  variant="h1"
                  className="card-header"
                >
                  <Box>
                    <img
                      src={configData?.logoUrl}
                      style={{ maxHeight: "70px" }}
                      alt="shape"
                    />
                  </Box>
                  Login
                </Typography>
              ) : (
                <Typography
                  sx={{ fontStyle: "normal", m: "auto", width: 1, py: 2 }}
                  variant="h1"
                  className="card-header"
                >
                  <Box>
                    <img
                      src={configData?.logoUrl}
                      style={{ maxHeight: "70px" }}
                      alt="shape"
                    />
                  </Box>
                  Resend Invite
                </Typography>
              )}
              <div className="card-body text-center">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row justify-content-center mb-3">
                    <div className="form form-group col-md-6">
                      <TextField
                        size="small"
                        data-v-ee71dd48=""
                        type="text"
                        autoComplete="username"
                        placeholder="Email"
                        name="email"
                        error={formik.errors.email ? true : false}
                        helperText={formik.errors.email}
                        id="email"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                        value={formik.values.email}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldError("email", "");
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center mb-3">
                    {flagLogin && (
                      <div className="form form-group col-md-6">
                        <TextField
                          size="small"
                          helperText={formik.errors.password}
                          data-v-ee71dd48=""
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          value={formik.values.password}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldError("password", "");
                          }}
                          error={formik.errors.password ? true : false}
                          placeholder="Password"
                          sx={{ py: 0 }}
                          autoComplete="new-password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Key />
                              </InputAdornment>
                            ),
                          }}
                          className="form-control"
                        />

                        {/* Validation error messages go here */}
                      </div>
                    )}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: 1, md: 0.5 },
                      m: "auto",
                      justifyContent: flagLogin ? "space-between" : "center",
                      alignItems: "center",
                    }}
                  >
                    {flagLogin ? (
                      <>
                        <FormControlLabel
                          sx={{ ".MuiTypography-root": { fontSize: "14px" } }}
                          control={<Checkbox defaultChecked />}
                          label="Remember me"
                        />
                        <Box>
                          <button
                            disabled={isSKeleton}
                            type="submit"
                            className={configData.buttonCommonClass}
                          >
                            Login
                          </button>
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <button
                          disabled={isSKeleton}
                          onClick={handleResend}
                          className={configData.buttonCommonClass}
                        >
                          Resend Invite
                        </button>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: 1, md: 0.5 },
                      py: 2,
                      m: "auto",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {flagLogin && (
                      <>
                        <Button
                          variant="text"
                          sx={{ fontStyle: "normal", fontSize: "14px" }}
                          onClick={() => navigate(PageRoutes.Register)}
                        >
                          Register now
                        </Button>
                        <Typography
                          onClick={() => navigate(PageRoutes.PasswordReset)}
                          variant="body2"
                          sx={{
                            cursor: "pointer",
                            fontStyle: "normal",
                            textTransform: "capitalize",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </>
                    )}
                  </Box>
                </form>
                <DialogBox
                  open={open}
                  handleClose={handleClose}
                  buttonAction={() => handleClose()}
                  buttonText={"Close"}
                  content={`You've been logged out of your account because we've detected another login ${configData.mainName} only permits a single login per user at any given time.`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
