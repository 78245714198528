import React, { useContext, useEffect, useRef, useState } from "react";
import VideoContainer from "../Video/Video";
import "./Session.css";
// @ts-ignore
import WT from "@sscale/wtsdk";
import { WatchContex } from "../../watch";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

/**
 * Watch Together Session
 *
 * WT.Session.onConnected - Method for setting up session when it ready
 * @param {onConnectedCallback} callback - Callback fired when session is ready
 * @callback onConnectedCallback
 * @param {Array} Participants - callback function contain an array of participants in the session
 *
 * WT.SessionListeners.onStreamCreated - Metod will allow you get information about new participants
 * @param {onStreamCreated} callback - Callback fired everytime when participant stream is reade
 * @callback onStreamCreated
 * @param {Object} ParticipantInfo - callback function contain object with participant information
 *
 * WT.ParticipantListeners.onParticipantLeft - Method will allow you get information about participant which left the session
 * @param {onParticipantLeftCallback} callback - Callback fired everytime when participant leave from the session
 * @callback onParticipantLeftCallback
 *
 * WT.Session.disconnect - Method will allow you disconnecting from the Session
 *
 * WT.Session.connect - Method will allow you connecting to the Session
 * @param {Object} SessionSettings - Information for creation the session
 * @param SessionSettings.sessionToken - session token
 * @param SessionSettings.wsUrl - WebSocket Url
 * @param SessionSettings.sessionId - The identifier of the session
 * @param {Object} UserInformation - Information about user
 * @param UserInformation.displayed_name - User ID
 *
 * WT.ErrorListeners.onSessionError - Method will allow you handling session errors
 * @param {onSesionErrorCallback} callback - Callbeck fired everytime when arises error with session
 * @callback onSesionErrorCallback
 * @param {Object} ErrorObject - Information about sesion error
 * @param code - error code
 * @param message - error message
 * @param requestTime - error time in unix timestamp
 */

let globalParticipants: any = {};
let localParticipant: any = null;
declare global {
  interface Window {
    getParticipantsState: any;
    getLocalParticipantState: any;
  }
}
window.getParticipantsState = () => {
  for (const participantId in globalParticipants) {
    const audioState = WT.Participant.isRemoteAudioEnabled(participantId);
    const videoState = WT.Participant.isRemoteVideoEnabled(participantId);
    console.log(`Participant ${participantId} audio state: ${audioState}`);
    console.log(`Participant ${participantId} video state: ${videoState}`);
  }
};

window.getLocalParticipantState = () => {
  if (localParticipant) {
    const audioState = WT.Participant.isAudioEnabled();
    const videoState = WT.Participant.isVideoEnabled();
    console.log(`Local participant audio state: ${audioState}`);
    console.log(`Local participant video state: ${videoState}`);
  }
};

const WatchTogether = ({ history, isRow = true }: any) => {
  // const {
  //   session,
  //   displayed_name: displayName,
  //   config,
  //   isViewerMode,
  // } = history.location.state;
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const { participants, setParticipants } = useContext(WatchContex);

  const participantsRef = useRef(participants);

  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);
  useEffect(() => {
    WT.SessionListeners.onConnected(() => {
      // Calls when new streams added
      WT.SessionListeners.onStreamCreated((participant: any) => {
        const { stream, participantId, local } = participant;
        participant.settings.mutedAudio = true;
        participant.settings.mutedVideo = true;
        if (!local) {
          globalParticipants[participantId] = participant;
        } else {
          localParticipant = participant;
        }

        setParticipants((prevParticipants: any) => {
          const isParticipantExist = prevParticipants.some(
            (p: any) => p.participantId === participantId
          );

          if (isParticipantExist) {
            return prevParticipants.map((p: any) => {
              if (p.participantId === participantId) {
                return participant;
              }
              return p;
            });
          }

          return [...participantsRef.current, participant];
        });
      });

      WT.ParticipantListeners.onParticipantLeft((participant: any) => {
        delete globalParticipants[participant.participantId];
        setParticipants(
          participantsRef.current.filter(
            (p: any) => p.participantId !== participant.participantId
          )
        );
      });

      WT.ParticipantListeners.onParticipantMediaStreamChanged(
        (mediaStreamChanged: any) => {
          const { participantId, mediaState, mediaType } = mediaStreamChanged;

          setParticipants((prevParticipants: any) => {
            const isParticipantExist = prevParticipants.some(
              (p: any) => p.participantId === participantId
            );

            if (isParticipantExist) {
              return prevParticipants.map((p: any) => {
                if (p.participantId === participantId) {
                  if (mediaState == "DISABLED" && mediaType == "AUDIO") {
                    p.settings.mutedAudio = false;
                  } else if (mediaState == "ENABLED" && mediaType == "AUDIO") {
                    p.settings.mutedAudio = true;
                  }
                  if (mediaState == "DISABLED" && mediaType == "VIDEO") {
                    p.settings.mutedVideo = false;
                  } else if (mediaState == "ENABLED" && mediaType == "VIDEO") {
                    p.settings.mutedVideo = true;
                  }
                  return p;
                }
                return p;
              });
            }
          });
        }
      );

      WT.ErrorListeners.onSessionError((event: any) => {
        // Error handling
        console.log(event.error);
        if (event.error.code === 107) {
          toast.error("Your device is not capable for watch party");
          // Handle full room error
        }
      });
    });
  }, []);

  const handleNavigateBack = () => {
    WT.Session.disconnect();
    history.goBack();
  };

  const toggleAudio = () => {
    if (WT.Participant.isAudioEnabled()) {
      WT.Participant.disableAudio();
      setIsMuted(true);
    } else {
      WT.Participant.enableAudio();
      setIsMuted(false);
    }
  };

  const toggleVideo = () => {
    if (WT.Participant.isVideoEnabled()) {
      WT.Participant.disableVideo();
      setIsVideoEnabled(true);
    } else {
      WT.Participant.enableVideo();
      setIsVideoEnabled(false);
    }
  };

  const shareScreen = () => {
    WT.Participant.startScreenSharing("test-video");
  };

  return (
    <Box>
      {participants.length ? (
        <div className="main-container-watch">
          <div className="content-wrapper">
            <div
              className="streams-container"
              style={{ flexDirection: isRow ? "row" : "column" }}
            >
              {participants.length
                ? participants.map((user: any) =>
                    user.stream ? (
                      <VideoContainer
                        key={user.participantId}
                        stream={user.stream}
                        participant={user.participantId}
                        isLocal={user.local}
                        isMuted={user.local ? isMuted : null}
                        isVideoEnabled={user.local ? isVideoEnabled : null}
                        toggleAudio={toggleAudio}
                        toggleVideo={toggleVideo}
                      />
                    ) : null
                  )
                : null}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default WatchTogether;
