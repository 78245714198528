import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useConfig from "../../../../hooks/useConfig";
import axios from "../../../../utils/axios";
import { APIRoutes } from "../../../../utils/constants";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { UserContext } from "../..";
import { toast } from "react-toastify";
import EllipsisContent from "../../../../Common/Component/EllipsisContent";
import HttpService from "../../../../hooks/Https-services";
import { useParams } from "react-router-dom";

const TeamCards = () => {
  const [isChecked, setIsChecked] = useState(-1);
  const {
    selectedTeam,
    setSelectedTeam,
    channel,
    selectAllTeamPlayers,
    setSelectAllTeamPlayers,
    selectedPlayer,
    channelData,
    selectedEvent,
    selectedSeason,
  }: any = useContext(UserContext);
  const [teams, setTeams] = useState([]);
  const [searchTeam, setSearchTeam] = useState("");
  const [isSkeleton, setIsSkeleton] = useState(true);

  // const handleCheckboxClick = () => {
  //   setIsChecked(!isChecked);
  // };
  const configData = useConfig();
  const { channel_id }: any = useParams();
  const updateChannel = async (teams: any) => {
    const updateConfigure = {
      Name: channelData,
      Teams: teams,
      Players: selectedPlayer,
      Events: selectedEvent,
      All: selectAllTeamPlayers,
      Seasons: selectedSeason,
    };

    try {
      const res = await HttpService.post(
        APIRoutes.UpdateChannel.replace(":id", channel_id),
        {
          configure: updateConfigure,
        }
      );
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const GetAllTeams = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetAllTeams);
      if (res) {
        setTeams(res.data.data);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    GetAllTeams();
  }, []);

  return (
    <>
      <Box sx={{ my: 1, width: 1 }}>
        <TextField
          placeholder="Search Teams"
          size="small"
          fullWidth
          onChange={(e: any) => setSearchTeam(e.target.value)}
        />
      </Box>
      {isSkeleton ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", py: 10, width: 1 }}
        >
          <CircularProgress
            size={48}
            sx={{
              color: "#bf311a",
            }}
          />
        </Box>
      ) : teams?.length != 0 ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: 1 }}>
          {teams
            ?.filter((el: any) => {
              if (searchTeam) {
                return el?.toLowerCase().includes(searchTeam.toLowerCase());
              } else {
                return el;
              }
            })
            .map((teamsName, index) => (
              <Box sx={{ borderBottom: "1px solid #dadada" }} key={index + 1}>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    position: "relative",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxHeight: "100px",
                  }}
                >
                  <EllipsisContent text={teamsName} />
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        updateChannel([...selectedTeam, teamsName]);
                        setSelectedTeam([...selectedTeam, teamsName]);
                      } else {
                        const index = selectedTeam.findIndex(
                          (element: any) => element == teamsName
                        );
                        if (index != -1) {
                          const teamData = selectedTeam.filter(
                            (e: any, ind: any) => ind != index
                          );

                          updateChannel(teamData);
                          setSelectedTeam(teamData);
                          const filterOutAllTeamData =
                            selectAllTeamPlayers?.filter(
                              (el: any) => el != teamsName
                            );
                          setSelectAllTeamPlayers(filterOutAllTeamData);
                        }
                      }
                    }}
                    disabled={
                      selectedTeam.length == 3 &&
                      !selectedTeam.includes(teamsName)
                    }
                    checked={selectedTeam?.includes(teamsName) ? true : false}
                  />
                </Box>
              </Box>
            ))}
        </Box>
      ) : (
        <Typography sx={{ my: 2, mx: "auto" }}>No data found</Typography>
      )}
    </>
  );
};

export default TeamCards;
