import {
  Box,
  Button,
  Chip,
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useConfig from "../../../../hooks/useConfig";
import UserDownloadCredit from "./UserDownloadCredit";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes, UserRoles } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import momentTimezone from "moment-timezone";
import moment from "moment-timezone";
import { Key, Link, Visibility, VisibilityOff } from "@mui/icons-material";
import DownloadPdfComponent from "../../../../Common/Component/DownloadPdfComponent";
import { LoadingButton } from "@mui/lab";
import DialogBox from "../../../../Common/Component/DialogBox";
import UserEmailHistory from "./UserEmailHistory";
import useCommonDetails from "../../../../Common/Hooks/useCommonDetails";
import { useFormik } from "formik";
const UserView = () => {
  const configData = useConfig();
  //   sendResetPasswordEmailById;
  const currentuserDetails = useCommonDetails();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonLoadingDeleteSub, setIsButtonLoadingDeleteSub] =
    useState(false);
  const [userDownloadCredit, setUserDownloadCredit] = useState<any>(null);
  const [userRoleValue, setUserRoleValue] = useState(1);
  const [useDetails, setUserDetails] = useState<any>(null);
  const [totalPage, setTotalPage] = useState(0);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [paymentHistory, setpaymentHistory] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  } as any);
  const { id }: any = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openPassword, setOpenPassword] = React.useState(false);

  const handleClickOpenPassword = () => {
    setOpenPassword(true);
  };

  const handleClosePassword = () => {
    formik.resetForm();
    setOpenPassword(false);
  };
  const handleSendResetPasswordEmail = async () => {
    setIsLoading(true);
    try {
      const res = await HttpService.post(
        APIRoutes.SendResetPasswordEmailById.replace(":id", id),
        {}
      );

      toast.success("Email Sent Successful");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const deactivateUser = async () => {
    if (useDetails?.Status !== 1) setIsButtonLoading(true);
    try {
      const res = await HttpService.post(
        `${APIRoutes.DeactivateUser.replace(":id", id)}?isActive=${
          useDetails?.Status === 1 ? "false" : "true"
        }`,
        {}
      );
      await GetUserDetailsByID();
      setIsButtonLoading(false);

      toast.success(
        `User ${
          useDetails?.Status === 1 ? "Deactivated" : "Activated"
        } Successfully`
      );
    } catch (error) {
      setIsButtonLoading(false);
    }
  };
  const handleDeletSub = async (sunId: string) => {
    setIsButtonLoadingDeleteSub(true);

    try {
      const res = await HttpService.post(
        `${APIRoutes.DeleteUsersSub.replace(":id", sunId)}`,
        {}
      );
      await GetUserDetailsByID();
      setIsButtonLoadingDeleteSub(false);

      toast.error(`User's Subscription Deleted Successfully`);
    } catch (error) {
      setIsButtonLoadingDeleteSub(false);
    }
  };
  const handleClickShowPassword = (flag: any) => {
    const item = { ...showPassword };
    item[flag] = !showPassword[flag];

    setShowPassword(item);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const validate = (values: any) => {
    const errors: any = {};

    const passwordRegx = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );

    if (!values.password) {
      errors.password = "Please fill the required fields";
    } else if (!passwordRegx.test(values.password)) {
      errors.password =
        "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Please fill the required fields";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      password: "",

      confirm_password: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }

      try {
        await updateUserPassword(values.password);
        toast.success(`User's Password Updated Successfully`);
        handleClosePassword();
      } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const updateUserPassword = async (password: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UpdateUserPassword.replace(":id", id),
        { password: password }
      );
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const GetUserDetailsByID = useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetUserDetailsById.replace(":id", id)
      );
      if (res.data.data) {
        setUserDetails(res.data.data);
        setUserRoleValue(res?.data?.data?.UserRoleID);
        setpaymentHistory(res.data.data?.PaymentHistory);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }, [id]);
  const GetRoles = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetRoles.replace(":id", id));
      if (res.data.data) {
        setUserRoles(res.data.data);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }, [id]);
  const GetUserDownloadCreditByID = useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetUserDownloadTokensById.replace(":id", id)
      );
      if (res.data.data) setUserDownloadCredit(res.data.data);
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }, [id]);
  const GetPaymentLogs = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetPaymentLogs}?page=${page}&userId=${id}`
      );
      if (res) {
        setPaymentLogs(res.data.data?.logs);
        setTotalPage(res.data.data?.total_page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, id]);
  const handleChangeRole = async (e: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UpdateUserRole?.replace(":id", id),
        {
          role_id: e.target.value,
        }
      );
      toast.success("User Role Updated Successfull");
      setUserRoleValue(e.target.value);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    Promise.all([GetUserDownloadCreditByID(), GetUserDetailsByID(), GetRoles()])
      .then((res) => {})
      .finally(() => {});
  }, [GetUserDownloadCreditByID, GetUserDetailsByID, GetRoles]);
  useEffect(() => {
    Promise.all([GetPaymentLogs()])
      .then((res) => {})
      .finally(() => {});
  }, [GetPaymentLogs]);
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 2, mt: 5 }}>
        <Box sx={{ width: 100, mb: 2 }}>
          <button
            style={{
              fontSize: "14px",
              padding: "8px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => navigate(PageRoutes.Users)}
            className={configData.buttonCommonClass}
          >
            <ArrowBackIcon />
            Back
          </button>
        </Box>

        <Stack gap={6}>
          <Box>
            <Stack>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Password Reset
              </Typography>
              <Stack
                sx={{ mt: 2 }}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"row"} gap={1}>
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {useDetails?.Email}
                    </Typography>
                  </Stack>
                  {useDetails && (
                    <LoadingButton
                      loading={isButtonLoading}
                      onClick={() => {
                        if (useDetails?.Status === 1) {
                          handleClickOpen();
                        } else {
                          deactivateUser();
                        }
                      }}
                      variant="contained"
                      disabled={useDetails?.Subscription?.subscription}
                      color={useDetails?.Status === 1 ? "error" : "success"}
                    >
                      {useDetails?.Status === 1 ? "Deactivate" : "Activate"}
                    </LoadingButton>
                  )}
                  {currentuserDetails?.UserRoleID === UserRoles.HTP_ADMIN &&
                    currentuserDetails?.UserID != id && (
                      <Select
                        size="small"
                        sx={{ textTransform: "capitalize" }}
                        value={userRoleValue}
                        onChange={(e: any) => handleChangeRole(e)}
                      >
                        {userRoles?.map((el: any) => (
                          <MenuItem
                            sx={{ textTransform: "capitalize" }}
                            value={el?.ID}
                          >
                            {el?.RoleName?.split("_")?.join(" ")}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                </Stack>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {currentuserDetails?.UserRoleID === UserRoles.HTP_ADMIN && (
                    <Box>
                      <button
                        disabled={isLoading}
                        onClick={handleClickOpenPassword}
                        style={{ fontSize: "14px" }}
                        className={configData.buttonCommonClass}
                      >
                        Reset Password
                      </button>
                    </Box>
                  )}
                  <Box>
                    <button
                      disabled={isLoading}
                      onClick={handleSendResetPasswordEmail}
                      style={{ fontSize: "14px" }}
                      className={configData.buttonCommonClass}
                    >
                      Send Password Reset
                    </button>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>

          {userDownloadCredit && (
            <Box>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  User Download Credit
                </Typography>
                <UserDownloadCredit
                  userDownloadCredit={userDownloadCredit}
                  GetUserDownloadCreditByID={GetUserDownloadCreditByID}
                />
              </Stack>
            </Box>
          )}
          {useDetails?.Subscription && (
            <Box>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Plan Details{" "}
                  {useDetails?.Subscription?.subscription?.id && (
                    <>
                      <IconButton
                        target="_blank"
                        href={`https://dashboard.stripe.com/subscriptions/${useDetails?.Subscription?.subscription?.id}`}
                      >
                        <Link color="primary" />{" "}
                      </IconButton>
                      <LoadingButton
                        loading={isButtonLoadingDeleteSub}
                        onClick={() =>
                          handleDeletSub(
                            useDetails?.Subscription?.subscription?.id
                          )
                        }
                        variant="contained"
                        color="error"
                      >
                        Cancel Subscription
                      </LoadingButton>
                    </>
                  )}
                </Typography>
                <Stack sx={{ mt: 2 }}>
                  {useDetails?.Subscription?.plan_type_id && (
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 600,
                        textTransform: "capitalize",
                      }}
                    >
                      {useDetails?.Subscription?.plan_type_id == 3
                        ? "Single Game Access"
                        : `${useDetails?.Subscription?.subscription?.plan?.interval}ly Access`}
                      {useDetails?.Subscription?.plan_type_id != 3 &&
                        useDetails?.Subscription?.plan_type_id && (
                          <Chip
                            sx={{ ml: 0.5 }}
                            label={
                              useDetails?.Subscription?.subscription
                                ?.pause_collection
                                ? "Paused"
                                : useDetails?.Subscription?.subscription?.status
                                    ?.split("_")
                                    ?.join(" ")
                            }
                            color={
                              useDetails?.Subscription?.subscription
                                ?.pause_collection
                                ? "info"
                                : useDetails?.Subscription.subscription
                                    ?.status != "active"
                                ? "error"
                                : "success"
                            }
                          />
                        )}
                    </Typography>
                  )}
                  {useDetails?.Subscription?.subscription?.pause_collection ? (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        fontStyle: "normal",
                        textTransform: "none",
                      }}
                    >
                      User's plan is paused until{" "}
                      {moment
                        .unix(
                          useDetails?.Subscription?.subscription
                            ?.pause_collection?.resumes_at
                        )
                        .format("MMM DD, YYYY")}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        fontStyle: "normal",
                        textTransform: "none",
                      }}
                    >
                      {!useDetails?.Subscription?.subscription
                        ?.cancel_at_period_end &&
                      useDetails?.Subscription?.plan_type_id != 3 &&
                      useDetails?.Subscription?.plan_type_id &&
                      !useDetails?.Subscription?.subscription?.canceled_at
                        ? `${
                            useDetails?.Subscription?.subscription?.plan
                              ?.amount / 100
                          } ${useDetails?.Subscription?.subscription?.plan?.currency?.toUpperCase()} (next payment 
                        ${momentTimezone
                          .unix(
                            useDetails?.Subscription.subscription
                              ?.current_period_end
                          )
                          .tz("America/New_York")
                          .format("YYYY-MM-DD")}
                        )`
                        : "No upcoming payments."}
                    </Typography>
                  )}

                  {useDetails?.Subscription?.plan_type_id != 3 &&
                    useDetails?.Subscription?.plan_type_id &&
                    !useDetails?.Subscription?.subscription
                      ?.pause_collection && (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                          fontStyle: "normal",
                          textTransform: "none",
                        }}
                      >
                        {!useDetails?.Subscription?.subscription
                          ?.cancel_at_period_end &&
                        !useDetails?.Subscription?.subscription?.canceled_at
                          ? `Renew 
                        ${momentTimezone
                          .unix(
                            useDetails?.Subscription.subscription
                              ?.current_period_end
                          )

                          .format("YYYY-MM-DD hh:mm A")}`
                          : "This plan will not auto-renew"}
                      </Typography>
                    )}
                </Stack>
              </Stack>
            </Box>
          )}
          {paymentHistory?.length !== 0 && (
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 600, my: 2 }}>
                Payment History{" "}
                {useDetails?.StripePayments[0]?.CostomerID && (
                  <IconButton
                    target="_blank"
                    href={`https://dashboard.stripe.com/customers/${useDetails?.StripePayments[0]?.CostomerID}`}
                  >
                    <Link color="primary" />{" "}
                  </IconButton>
                )}
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Payment Invoices</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Game</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentHistory
                      ?.sort(
                        (a: any, b: any) =>
                          b?.payment?.created - a?.payment?.created
                      )
                      ?.map((row: any, index: any) => (
                        <TableRow
                          key={index + 1}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <DownloadPdfComponent row={row} />
                          </TableCell>
                          <TableCell align="left">
                            {row?.payment?.description
                              ? row?.payment?.description
                              : row?.payment?.billing_reason ==
                                "subscription_create"
                              ? "Subscription creation"
                              : row?.payment?.billing_reason ==
                                "subscription_update"
                              ? "Subscription update"
                              : "One time pass creation"}
                          </TableCell>{" "}
                          <TableCell align="left">
                            ${(row?.payment?.amount / 100).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {moment
                              .unix(row?.payment?.created)
                              .utc()
                              .format("MMMM DD , YYYY hh:mm A")}{" "}
                            UTC
                          </TableCell>
                          <TableCell align="left">
                            {row?.payment?.status}
                          </TableCell>
                          <TableCell align="left">
                            {row?.payment?.description ==
                            "Gift Card Purchase" ? (
                              <>-</>
                            ) : (
                              <Button
                                variant="text"
                                onClick={() => {
                                  if (
                                    row?.invoice?.metadata?.PlanTypeID == "3"
                                  ) {
                                    navigate(
                                      PageRoutes.Watch.replace(
                                        ":gameId",
                                        row?.invoice?.metadata?.GameID
                                      )
                                    );
                                  }
                                }}
                              >
                                {row?.invoice?.metadata?.GameID}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600, my: 2 }}>
              Customer Emails
            </Typography>
            <UserEmailHistory />
          </Box>
          {paymentLogs?.length !== 0 && (
            <Box>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Payment Logs{" "}
                  {useDetails?.StripePayments[0]?.CostomerID && (
                    <IconButton
                      target="_blank"
                      href={`https://dashboard.stripe.com/customers/${useDetails?.StripePayments[0]?.CostomerID}`}
                    >
                      <Link color="primary" />{" "}
                    </IconButton>
                  )}
                </Typography>
                <Stack sx={{ mt: 2, gap: 1 }}>
                  {paymentLogs?.map((el: any, index: number) => {
                    return (
                      <Typography
                        variant="body2"
                        sx={{ textTransform: "none", fontStyle: "normal" }}
                      >
                        {el?.AlertMessage?.replace("You", "User")?.replace(
                          "your",
                          "their"
                        )}{" "}
                        at{" "}
                        {moment(el.createdAt)
                          .utc()
                          .format("hh:mm A z [on] YYYY-MM-DD")}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
        <DialogBox
          open={open}
          handleClose={handleClose}
          buttonAction={() => handleClose()}
          butttonTextSecondary={"Deactivate User"}
          buttonActionSecondary={async () => {
            await deactivateUser();
            handleClose();
          }}
          buttonText={"Close"}
          content={
            <Stack sx={{ textAlign: "center" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  fontStyle: "normal !imporatant",
                  textTransform: "none",
                }}
              >
                {" "}
                Are you sure?
              </Typography>
              <Typography sx={{ fontStyle: "normal", textTransform: "none" }}>
                This will immediately revoke access to the system for the user.
                Are you sure you want to deactivate this user?
              </Typography>
            </Stack>
          }
        />
        <DialogBox
          open={openPassword}
          handleClose={handleClosePassword}
          buttonAction={() => handleClosePassword()}
          butttonTextSecondary={"Reset Password"}
          buttonActionSecondary={async () => {
            formik.handleSubmit();
          }}
          buttonText={"Close"}
          content={
            <Stack sx={{ textAlign: "center", maxWidth: 360, m: "auto" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  fontStyle: "normal !imporatant",
                  textTransform: "none",
                }}
              >
                {" "}
                Reset User Password
              </Typography>

              <OutlinedInput
                size="small"
                data-v-ee71dd48=""
                type={showPassword.password ? "text" : "password"}
                name="password"
                id="password"
                fullWidth
                value={formik.values.password}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.errors.password = "";
                }}
                error={formik.errors.password ? true : false}
                placeholder="Password"
                sx={{ py: 0 }}
                autoComplete="off"
                startAdornment={
                  <InputAdornment position="start">
                    <Key />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("password")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword.password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                className="form-control"
              />
              <FormHelperText sx={{ color: "#d50000", px: 2 }}>
                {" "}
                {formik.errors.password}
              </FormHelperText>

              <TextField
                size="small"
                fullWidth
                data-v-ee71dd48=""
                type={showPassword.confirm_password ? "text" : "password"}
                name="confirm_password"
                id="confirm_password"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword("confirm_password")
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirm_password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.errors.confirm_password = "";
                  setConfirmPasswordError("");
                }}
                onBlur={(e) => {
                  if (formik.values.password) {
                    if (formik.values.password != e.target.value) {
                      setConfirmPasswordError(
                        "Confirm password do not match with entered password"
                      );
                    } else {
                      setConfirmPasswordError("");
                    }
                  }
                }}
                error={
                  formik.errors.confirm_password || confirmPasswordError
                    ? true
                    : false
                }
                helperText={
                  formik.errors.confirm_password || confirmPasswordError
                }
                value={formik.values.confirm_password}
                placeholder="Confirm Password"
                className="form-control"
              />
            </Stack>
          }
        />
        {totalPage !== 1 && totalPage !== 0 && (
          <Pagination
            count={totalPage}
            sx={{ mt: 2 }}
            // @ts-ignore
            onChange={handleChangePage}
            page={page}
            showFirstButton
            showLastButton
          />
        )}
      </Box>
    </Container>
  );
};
export default UserView;
