import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import useAxiosPrivate, { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import NOTEAM from "../../../src/assets/img/noteam.png";
import momentTimezone from "moment-timezone";

import FlipCountdown from "@rumess/react-flip-countdown";
import moment from "moment";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import useConfig from "../../hooks/useConfig";
// @ts-ignore
import WT from "@sscale/wtsdk";

import VideoPlayerLive from "./VideoPlayerLive";

import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";
import useCheckHasPermission from "../../hooks/useCheckHasPermission";
import { Close } from "@mui/icons-material";
import LoadingContext from "../../Common/Contex/LoadingContex";

const userTimeZone = momentTimezone.tz.guess();

const Watch = () => {
  const { gameId }: any = useParams();

  const auth = getCurrentAuth();
  const navigate = useNavigate();
  const userDetails = useCommonDetails();
  const [isGameSubscribe, setIsGameSubscribe] = useState(false);
  const hasPermisstion = useCheckHasPermission(userDetails, "stopwatch");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompalate, setComplate] = useState(false);
  const [name, setName] = useState(
    `${userDetails.FirstName} ${userDetails.LastName}`
  );
  const [searchParams, seSearchParams] = useSearchParams();
  const meetingIdFromUrl = searchParams.get("meeting_id");
  const [subscriptionDetails, setSubscriptionDetials] = useState<any>({});
  const [gameDetails, setGameDetails] = useState({
    game_id: 0,
    game_date_time: "",
    game_date: "",
    game_time: "",
    away_team_id: 0,
    away_team: "",
    away_team_mascot: "",
    away_team_desc: "",
    game_id_tts: "",
    away_team_short: "",
    away_team_logo: "",
    home_team_id: 0,
    game_id_tpe: "",
    home_team: "",

    home_team_mascot: "",
    home_team_desc: "",
    home_team_short: "",
    home_team_logo: "",
    facility_name: "",

    pad_name: "",
    watch_url: "",
    watch_url_away: "",
    stats_embed: "",
    card_logo: "",
    card_description: "",
    monetization_type: 0,
    caption: "",
  } as any);
  const GetUserSubscriptionData = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetials(res?.data?.data);
        const findGame = res?.data?.data?.data?.findIndex(
          (el: any) => el?.game_id == gameId
        );
        if (findGame != -1) {
          setIsGameSubscribe(true);
        }
      }
    } catch (error) {}
  };

  const configData = useConfig();
  const {
    setReceivedMessage,
    handleChangeColleps,
    errorStyle,
    setReceivedImage,
    setErrorStyle,
  }: any = useContext(LoadingContext);
  const GetGameMessge = async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetMessageByGameID}/${gameId}`
      );
      if (res?.data?.data) {
        setReceivedMessage(res?.data?.data?.Message);
        setReceivedImage(res?.data?.data?.Image);
        setErrorStyle({
          ...errorStyle,
          Placement: res?.data?.data?.Placement,
          IsPause: res?.data?.data?.IsPause,
          Speed: res?.data?.data?.Speed,
          Type: res?.data?.data?.Type,
          Time: res?.data?.data?.Time,
          Link: res?.data?.data?.Link,
          Linking: res?.data?.data?.Linking,
        });

        handleChangeColleps();
      }
    } catch (error) {}
  };
  const GetGameDetail = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await HttpService.getGame(
        `${APIRoutes.GameDetails}/${id}?customer=${configData.customer}`
      );

      if (res?.data?.data[0]) {
        if (
          (res?.data?.data[0]?.monetization_type == 0 ||
            res?.data?.data[0]?.monetization_type == 1) &&
          !auth.AccessToken
        ) {
          navigate(PageRoutes?.Login);
        }
        setGameDetails(res?.data?.data[0]);
      } else {
        setGameDetails(null);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const checkUserSubscribe = async () => {
    try {
      const res = await HttpService.get(APIRoutes.CheckSubscribe);
      if (res?.data?.data?.isSubscribe) {
        setIsSubscribed(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    localStorage.removeItem("token");

    if (hasPermisstion) {
      setComplate(true);
    }
    if (auth.AccessToken) {
      checkUserSubscribe();
      GetUserSubscriptionData();
    }
    GetGameMessge();
    GetGameDetail(gameId || "");
    return () => {
      localStorage.removeItem("token");
    };
  }, [gameId]);

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            height: "80vh",
          }}
        >
          <CircularProgress
            size={60}
            sx={{
              color: "#bf311a",
            }}
          />
        </Box>
      ) : gameDetails ? (
        <div className="container-fluid">
          {" "}
          {!isCompalate ? (
            <Box
              sx={{
                display: isCompalate ? "none" : "flex",
                minHeight: "calc( 100vh - 210px)",
                flexDirection: "column",
                justifyContent: "center",

                height: 1,
              }}
            >
              {" "}
              <Box>
                <div className="col-12 text-center">
                  <img
                    src={gameDetails.away_team_logo || NOTEAM}
                    className="logoImage"
                    alt=""
                  />
                  <img
                    src={gameDetails.home_team_logo || NOTEAM}
                    className="logoImage"
                    alt=""
                  />
                </div>
                <div className="col-12 text-center">
                  {!gameDetails?.card_image_url && (
                    <h3 className="fw-bold fst-italic">
                      {gameDetails.away_team} @ {gameDetails.home_team}
                    </h3>
                  )}
                </div>
                <div className="col-12 text-center">
                  <div className="pt-2 fw-bold fst-italic fs-5">
                    <div className="mr-2">
                      <i className="far fa-calendar"></i>
                    </div>
                    {momentTimezone
                      .tz(
                        gameDetails.game_date_time,
                        "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                        "America/New_York"
                      )
                      .tz(userTimeZone)
                      .format("h:mm a z")}{" "}
                  </div>
                </div>
                {gameDetails?.caption && (
                  <div className="col-12 text-center">
                    <div className="fw-bold fst-italic fs-5">
                      {gameDetails?.caption}
                    </div>
                  </div>
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <FlipCountdown
                  hideYear
                  hideMonth
                  endAtZero
                  dayTitle="Days"
                  hourTitle="Hours"
                  minuteTitle="Minutes"
                  secondTitle="Seconds"
                  size="medium"
                  onTimeUp={() => {
                    setComplate(true);
                  }}
                  endAt={momentTimezone
                    .tz(
                      gameDetails.game_date_time,
                      "YYYY-MM-DD HH:mm:ss.SSSSSS A Z",
                      "America/New_York"
                    )
                    .tz(userTimeZone)
                    .subtract(15, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss")} // Date/Time
                  titlePosition={"bottom"}
                />{" "}
              </Box>
            </Box>
          ) : (
            <div className="main-content padding-top">
              <div className="container-fluid">
                {gameDetails.game_id ? (
                  <div className="row justify-content-center">
                    {((gameDetails.monetization_type == 0 &&
                      !isSubscribed &&
                      !isGameSubscribe) ||
                      subscriptionDetails?.subscription?.pause_collection) && (
                      <Box>
                        <div className="col-12 text-center">
                          <img
                            src={gameDetails.away_team_logo || NOTEAM}
                            className="logoImage"
                            alt=""
                          />
                          <img
                            src={gameDetails.home_team_logo || NOTEAM}
                            className="logoImage"
                            alt=""
                          />
                        </div>
                        <div className="col-12 text-center">
                          {!gameDetails?.card_image_url && (
                            <h3 className="fw-bold fst-italic">
                              {gameDetails.away_team} @ {gameDetails.home_team}
                            </h3>
                          )}
                        </div>
                        <div className="col-12 text-center">
                          <div className="pt-2 fw-bold fst-italic fs-5">
                            <div className="mr-2">
                              <i className="far fa-calendar"></i>
                            </div>
                            {momentTimezone
                              .tz(
                                gameDetails.game_date_time,
                                "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                                "America/New_York"
                              )
                              .tz(userTimeZone)
                              .format("h:mm a z")}{" "}
                          </div>
                        </div>
                        {gameDetails?.caption && (
                          <div className="col-12 text-center">
                            <div className="fw-bold fst-italic fs-5">
                              {gameDetails?.caption}
                            </div>
                          </div>
                        )}
                      </Box>
                    )}
                    <div className="col-12 text-center">
                      {gameDetails.monetization_type ? (
                        <VideoPlayerLive
                          subscriptionDetails={subscriptionDetails}
                          gameDetails={gameDetails}
                        />
                      ) : (
                        <Box
                          sx={{
                            width: { xs: 1, md: 0.9 },
                            margin: "10px auto",
                          }}
                        >
                          {(isSubscribed || isGameSubscribe) &&
                          !subscriptionDetails?.subscription
                            ?.pause_collection ? (
                            <VideoPlayerLive
                              subscriptionDetails={subscriptionDetails}
                              gameDetails={gameDetails}
                            />
                          ) : (
                            <Box>
                              <Button
                                sx={{
                                  my: 2,
                                  color: "#fff",
                                  background: "#bf311a",
                                  ":hover": {
                                    background: "#bf311a",
                                  },
                                }}
                                onClick={() => {
                                  navigate(
                                    `${PageRoutes.Packages}?id=${gameId}`
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                  }}
                                  variant="body1"
                                >
                                  Buy a Plan to watch this game
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <h3 className="fw-bold fst-italic">
                        No Data Found Please Go To Events Page
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="col-12 text-center" style={{ margin: "100px 0px" }}>
          <div className="fw-bold fst-italic fs-5">No game found</div>
        </div>
      )}
    </Box>
  );
};

export default Watch;
