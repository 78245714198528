import * as React from "react";
import { useState, createContext } from "react";
import useConfig from "../../hooks/useConfig";
import Box from "@mui/material/Box";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

import "./index.css";
import { Chip, CircularProgress, useMediaQuery } from "@mui/material";
import CreateChannel from "./CreateChannel";
import SelectTeamm from "./SelectTeam";
import ChannelPreferencess from "./ChannelPreferences";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import commonDetailSlice from "../../store/reducers/commonReducer";
import HttpService from "../../hooks/Https-services";

export const UserContext: any = createContext(null);

function MyNAHLTV() {
  const configData = useConfig();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );
  let { id }: any = useParams();
  const isSmallScreen2 = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const steps = ["", "", ""];
  const [step1, setStep1] = useState(false);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [channel, setChannel] = useState(null);
  const [channelData, setChannelName] = React.useState("");

  const handleNext = () => {
    let newSkipped = skipped;

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    id -= 1;
    navigate(
      PageRoutes.MyNAHLTV.replace(":id", id).replace(":channel_id", channel_id)
    );
  };

  const [loading, setLoading] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isSkeleton, setIsSkeleton] = React.useState(true);
  const [isSelectAllPlayer, setIsSelectAllPlayers] = useState(false);
  const [isSelectAllEvent, setIsSelectAllEvents] = useState(false);
  const [selectAllTeamPlayers, setSelectAllTeamPlayers] = useState([]);
  const location = useLocation();
  const userDetails = useCommonDetails();
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    try {
      const res = await HttpService.get(APIRoutes.GetAllChannel);
      let submitdata = { ...data };
      if (res.data.data == null || res.data.data?.length == 0) {
        submitdata.isChannelCreated = false;
      } else {
        const { Name, Teams, Players } = res?.data?.data[0]?.Configuration;
        if (!Name || Teams?.length == 0 || Players?.length == 0) {
          submitdata.isChannelCreated = false;
        } else {
          submitdata.isChannelCreated = true;
        }
      }
      dispatch(commonDetailSlice.actions.removecommonDetails());
      dispatch(commonDetailSlice.actions.setcommonDetails(data));
    } catch (error) {}
  }
  const { channel_id }: any = useParams();

  const GetChannel = async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetChannel.replace(":id", channel_id)
      );
      if (res.data.data) {
        setChannel(res.data.data);
        setSelectedTeam(res.data.data.Configuration?.Teams);
        setSelectedSeason(res.data.data.Configuration?.Seasons || []);
        setSelectedEvent(res.data.data.Configuration?.Events);
        setChannelName(res.data.data.Configuration?.Name);
        setSelectedPlayer(res.data.data.Configuration?.Players);
        setSelectAllTeamPlayers(res.data.data.Configuration?.All);
        if (res?.data?.data.Configuration?.Events?.length == 0) {
          setIsSelectAllEvents(true);
        }
        setIsSkeleton(false);
      }
      await getEntries(userDetails);
      setIsSkeleton(false);
    } catch (error: any) {
      setIsSkeleton(false);
      toast.error(error.response.data.message);
    }
  };

  React.useEffect(() => {
    if (channel_id != 0) {
      if (process.env.REACT_APP_FEATURE !== "true") {
        navigate({
          pathname: PageRoutes.PersonalChannel.replace(":id", "0"),
          search: "?tab_id=1",
        });
      }
      GetChannel();
    } else {
      if (process.env.REACT_APP_FEATURE !== "true") {
        navigate({
          pathname: PageRoutes.PersonalChannel.replace(":id", "0"),
          search: "?tab_id=1",
        });
      }
      setIsSkeleton(false);
    }
  }, [location.pathname]);
  return (
    <>
      <UserContext.Provider
        value={{
          selectedTeam,
          setSelectedTeam,
          selectedPlayer,
          setSelectedPlayer,
          selectedSeason,
          setSelectedSeason,
          channel,
          isSelectAllPlayer,
          setIsSelectAllPlayers,
          isSelectAllEvent,
          setIsSelectAllEvents,
          selectAllTeamPlayers,
          setSelectAllTeamPlayers,
          selectedEvent,
          channelData,
          setChannelName,
          setSelectedEvent,
          setLoading,
          loading,
        }}
      >
        {isSkeleton ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: 10,
              width: 1,
            }}
          >
            <CircularProgress
              size={48}
              sx={{
                color: "#bf311a",
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "#edf2f7",
                  py: "12px",
                  // width: { xs: "100%", md: "95%" },
                  // margin: "0 auto",
                  px: { xs: 0, md: 1 },
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <OndemandVideoIcon
                    sx={{ color: configData.primary }}
                    fontSize="large"
                  />
                  <h3
                    className="section-heading"
                    style={{
                      marginLeft: "8px",
                      fontSize: isSmallScreen2 ? "19px" : "22px",
                    }}
                  >
                    {" "}
                    Personal Channel{" "}
                    <span>
                      <Chip size="small" color="error" label="Beta" />
                    </span>
                  </h3>
                </Box>
              </Box>
            </Box>
            <section className="text-center">
              {/* <section style={{marginLeft:"50px"}}> */}

              <Box sx={{ px: 2 }}>
                {id != 0 ? (
                  <>
                    {id == 1 ? (
                      <CreateChannel
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    ) : id == 2 ? (
                      <SelectTeamm
                        handleNext={handleNext}
                        handleBack={handleBack}
                        step={1}
                      />
                    ) : // <SelectTeam handleNext={handleNext} handleBack={handleBack}/>
                    id == 3 ? (
                      // <ChannelPreference handleNext={handleNext} handleBack={handleBack}/>
                      <ChannelPreferencess
                        handleNext={handleNext}
                        handleBack={handleBack}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Box className="container-fluid" sx={{ mt: 20 }}>
                    <h2 className="text-body-secondary">
                      Welcome to My{configData.personalChannel}, your home for
                      exclusive, <br></br> personalized and customizable{" "}
                      {configData.mainName} content. <br></br> Click the button
                      below to get started.
                    </h2>
                    <div className="build-channel mx-auto">
                      <button
                        style={{ cursor: "pointer" }}
                        className={configData.buttonCommonClass}
                        onClick={() =>
                          navigate(
                            PageRoutes.MyNAHLTV.replace(":id", "1").replace(
                              ":channel_id",
                              "0"
                            )
                          )
                        }
                      >
                        Build my First Channel
                      </button>
                    </div>
                  </Box>
                )}
              </Box>
            </section>
          </Box>
        )}
      </UserContext.Provider>
    </>
  );
}

export default MyNAHLTV;
