import {
  Pause,
  PlayArrow,
  Chat,
  Visibility,
  VisibilityOff,
  Info,
  Sync,
  PlusOneOutlined,
  Add,
  Remove,
} from "@mui/icons-material";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  CircularProgress,
  ThemeProvider,
  createTheme,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// @ts-ignore
import WT from "@sscale/wtsdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HokeyPlayIngIcon from "../../../assets/icons/HokeyPlayingIcon";
import BitMovingPlayer from "../../../Common/Component/VideoPlayer/bitMovingPlayer";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import GameCenterBoard from "../../GameCenter";
import ChatComponent from "../ChatComponent";
import ClipsData, { formatTime } from "../ClipsData";
import HighLight from "../HightLight";
import WatchStyle from "../Watch.style";
import { ChatRoom } from "amazon-ivs-chat-messaging";
import momentTimezone from "moment-timezone";

import { v4 as uuid } from "uuid";
import moment, { duration } from "moment";

import useConfig from "../../../hooks/useConfig";
import { APIRoutes, CATEGORY, INSIDER_PLAN_ID } from "../../../utils/constants";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { onValue, ref } from "firebase/database";
import { db, makeGetRequest } from "../../../service";
import Login from "../WatchParty/Login/Login";
import { toast } from "react-toastify";
import { Style } from "./PlayerPage.Style";

import { initializeSyncSdk } from "../../../service/SynchronizeService";
import { handleSubmit, off, on } from "../../../service/PubSub";
import { useDispatch } from "react-redux";
import roomDetails from "../../../store/reducers/roomReducer";
import { WatchContex } from "../watch";
import WatchTogether from "../WatchParty/Session/Session";
import {
  faPeopleArrows,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import VideoPlayerForChannel from "../../PersonalChannel/VideoCard/VideoPlayerForChannel";
import MatchCard from "../../PersonalChannel/MatchCard";
import HttpService from "../../../hooks/Https-services";
import { PersonalChannelApi } from "../../PersonalChannel/PersonalChannel.Api";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import Loader from "../../../Common/Component/Loader";
import VideoPlayerSideBar from "../VideoPlayerSideBar";
import ClipsButtons from "../ClipsButtons";
import CustomChangeButton from "../CustomChangeButton";
import "../index.css";
const userTimeZone = momentTimezone.tz.guess();
const VideoPlayerLive = ({ gameDetails, subscriptionDetails }: any) => {
  const [chatRoom, setChatRoom] = useState<any>([]);
  const [isGameSubscribe, setIsGameSubscribe] = useState(false);
  const { gameId }: any = useParams();
  const [videoAway, setVideoAway] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentClip, setCurrentClip] = useState<any>(null);
  const userData = useCommonDetails();
  const [search, setSearch] = useState({ plays: "", hightlight: "" });
  const playerRef = React.useRef<any>("");
  const [isGameCenterLoaded, setIsGameCenterLoaded] = useState(false);
  const classes = Style;
  const videoRef: any = useRef();
  const [liniusAccessToken, setLiniusAccessToken] = useState({} as any);
  const [currentVideoData, setCurrentVideoData] = useState({} as any);
  const [isSeeking, setIsSeeking] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [checked, setChecked] = useState(false);
  const [isSkeletonList, setIsSkeletonList] = React.useState(true);
  const [thubmnails, setThumbnails] = useState([] as any);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRefLinius: any = React.useRef(null);
  const [pageData, setPageData] = useState({} as any);
  const [page, setPage] = React.useState<any>(1);
  const [activeClip, setActiveClip] = useState("");
  const configData = useConfig();
  const {
    openColleps,
    receivedMessage,
    setReceivedMessage,
    CloseColleps,
  }: any = useContext(LoadingContext);
  const [isDownloadStarted, setIsDownLoadStarted] = useState(false);
  const [isSkeleton, setIsSKeleton] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [gameData, setGameData] = useState({} as any);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [liveData, setLiveData] = useState<any>({});
  const userDetails = useCommonDetails();
  const [homeAway, setHomeAway] = useState("0");
  const [selectedTab, setSelectedTab] = useState("0");
  const [selectedTabAction, setSelectedTabAction] = useState("GOAL");
  const [allDownloadVideo, setAllDownloadVideo] = useState([] as any);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const handleChangeClips = (e: any) => {
    const item: any = { ...currentClip };
    item[e.target.name] = e.target.value;
    setCurrentClip(item);
  };
  const handleTabChangeAction = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    setSelectedTabAction(newValue);
  };

  const handleTabChange = (event: any, newValue: any) => {
    if (!newValue) {
      return;
    }
    if (newValue == "HOME") {
      setHomeAway(gameDetails?.home_team_id_tpe);
    } else {
      setHomeAway(gameDetails?.away_team_id_tpe);
    }
    setSelectedTab(newValue);
  };
  function handleError(error: any) {
    toast(error?.message, { type: "error" });
  }
  const GetAllLinusDownloadVideo = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetAllLinusDownloadVideo);

      setAllDownloadVideo(res.data.data);
    } catch (error) {}
  };
  const { participants, setParticipants } = useContext(WatchContex);
  const getGameDetails = async () => {
    let qurey: any = {
      game_id: gameDetails?.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      startup_new(array);
      setGameData(data);

      setIsSKeleton(false);
    } catch (error) {
      setIsSKeleton(false);
    }
  };
  const getGameLiveDetails = async () => {
    let qurey: any = {
      game_id: gameDetails?.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData(data);

      setIsSKeleton(false);
    } catch (error) {
      setIsSKeleton(false);
    }
  };
  const getGameRoster = async () => {
    let qurey: any = {
      game_id: gameDetails.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_rosters",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData({
        ...gameData,
        away_skaters: res.away_players,
        home_skaters: res.home_players,
      });
    } catch (error) {}
  };
  const getGameEvent = async () => {
    let qurey: any = {
      game_id: gameDetails.game_id_tts,
    };

    try {
      const res = await makeGetRequest(
        "get_game_events",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: gameDetails.game_id_tts,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${gameDetails.game_id_tts}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData({
        ...gameData,
        events: { [liveData.period.split(" ")[1]]: res.events },
      });
    } catch (error) {}
  };
  function GetRosterData(game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();

    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_game_rosters?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
          if (result) {
          }
        }
      };

      xmlHttp.send(null);
    }
  }
  function GetGameData(prefix: any, game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();
    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_team_info?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;
        var oCell;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
        }
      };

      xmlHttp.send(null);
    }
  }

  function startup_new(channels: any) {
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db, firebase_channel);
      // eslint-disable-next-line no-loop-func
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;
          const data = fb_data.clock_data;

          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }

          if (data.rosters == 1) {
            getGameRoster();
          }
          if (data.events == 1) {
            getGameLiveDetails();
          }
          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
          if (last_game_id != data.game_id) {
            GetGameData(prefix + "_", data.game_id);
            GetRosterData(data.game_id);
            last_game_id = data.game_id;
          }
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }
  useEffect(() => {
    if (gameDetails?.game_id_tts) {
      getGameDetails();
    }
  }, [gameDetails]);

  const [name, setName] = useState(
    `${userData.FirstName} ${userData.LastName}`
  );
  const meetingIdFromUrl = searchParams.get("meeting_id");
  const [clipsData, setClipsData] = useState<
    {
      video_clip_uuid: string;
      start_time: number;
      end_time: number;
      name: string;
      category: string;
      game_details: any;
      createdAt: any;
      active: boolean;
      duration: number;
    }[]
  >();
  const [hightLighData, setHighLighData] = useState<
    {
      video_clip_uuid: string;
      start_time: number;
      team_logo_url: any;
      name: string;
      player_first_name: any;
      event_time: any;
      team_short_name: any;
      period: any;
      clip_name: any;
      player_jersey_number: any;
      player_last_name: any;
      player_position: any;
      duration: number;
    }[]
  >();
  const [running, setRunning] = useState(true);
  const [activeIcon, setActiveIcon] = useState<any>({ stats: true });
  function playVideo() {
    setRunning(true);

    playerRef.current.play();
  }

  const [videoList, setVideoList] = useState([]);

  const GetHighLightDetails = useCallback(async () => {
    setIsloading(true);
    try {
      const res = await HttpService.get(
        APIRoutes.GetTeamHighLights.replace(":id", gameDetails?.game_id_tpe)
          .replace(":page", page)
          .replace(":value", selectedTab.toString())
          .replace(":teamData", homeAway)
          .replace(":action", selectedTabAction)
      );

      if (res?.data?.data) {
        setVideoList(res?.data?.data?.content);
        setPageData(res?.data?.data);
        setCurrentVideoData(res?.data?.data?.content[0]);
        setActiveClip(res?.data?.data?.content[0]?.id);
        setIsloading(false);
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
    }
  }, [selectedTab, selectedTabAction, page]);

  const GetHighLightDetailsForOld = async () => {
    try {
      const res = await HttpService.getapi(
        APIRoutes.HighLights.replace(":id", gameId)
      );

      if (res?.data?.data?.length > 0) {
        const setDataSchema = res?.data?.data?.map((el: any) => {
          return {
            video_clip_uuid: el?.uuid,
            start_time: Math.round(el?.start_time),
            team_logo_url: el?.team_logo_url,
            event_time: el?.event_time,
            name: el?.clip_name,
            period: el?.period,
            clip_name: el?.clip_name,
            player_first_name: el?.player_first_name,
            team_short_name: el?.team_short_name,
            player_jersey_number: el?.player_jersey_number,
            player_last_name: el?.player_last_name,
            player_position: el?.player_position,
            duration: el?.clip?.clip_duration,
          };
        });

        setHighLighData(setDataSchema);
      }
    } catch (error) {}
  };

  function pauseVideo() {
    setRunning(false);

    playerRef.current.pause();
  }

  const tokenProvider = async (
    selectedUsername: any,
    isModerator: any,
    avatarUrl: any
  ) => {
    const permissions = isModerator
      ? ["SEND_MESSAGE", "DELETE_MESSAGE", "DISCONNECT_USER"]
      : ["SEND_MESSAGE"];
    const room = await HttpService.post(APIRoutes.CreateRoom, {
      game_id: gameId,
    });

    const data = {
      arn: room.data.data.Arn,
      userId: `${selectedUsername}.${uuid()}`,
      attributes: {
        username: `${selectedUsername}`,
        avatar: `${avatarUrl}`,
      },
      capabilities: permissions,
    };

    let token: any = JSON.parse(localStorage.getItem("token") as any);

    try {
      if (token) {
        if (
          isTokenExpired(token?.tokenExpirationTime) ||
          isTokenExpired(token?.sessionExpirationTime)
        ) {
          const response = await HttpService.post(APIRoutes.GetToken, data);

          const body = JSON.parse(response.data.data.body);
          token = {
            token: body.token,
            sessionExpirationTime: new Date(body.sessionExpirationTime),
            tokenExpirationTime: new Date(body.tokenExpirationTime),
          };
          searchParams.set("id", data.userId);
          setSearchParams(searchParams);
          localStorage.setItem("token", JSON.stringify(token));
        } else {
          token = token;
        }
      } else {
        const response = await HttpService.post(APIRoutes.GetToken, data);

        const body = JSON.parse(response.data.data.body);
        token = {
          token: body.token,
          sessionExpirationTime: new Date(body.sessionExpirationTime),
          tokenExpirationTime: new Date(body.tokenExpirationTime),
        };

        setSearchParams((prev: any) => [
          ...prev.entries(),
          ["id", data.userId],
        ]);

        localStorage.setItem("token", JSON.stringify(token));
      }
    } catch (error) {
      console.error("Error:", error);
    }

    return token;
  };

  const connectRoom = () => {
    // Instantiate a chat room
    let token: any = JSON.parse(localStorage.getItem("token") as any);
    if (token) {
      if (
        isTokenExpired(token?.tokenExpirationTime) ||
        isTokenExpired(token?.sessionExpirationTime)
      ) {
        const room = new ChatRoom({
          regionOrUrl: "us-east-1",
          tokenProvider: () =>
            tokenProvider(
              `${userData?.FirstName} ${userData?.LastName}`,
              false,
              userData?.ProfileImage
            ) as any,
        });

        setChatRoom(room);

        // Connect to the chat room
        room.connect();
      }
    } else {
      const room = new ChatRoom({
        regionOrUrl: "us-east-1",
        tokenProvider: () =>
          tokenProvider(
            `${userData?.FirstName} ${userData?.LastName}`,
            false,
            userData?.ProfileImage
          ) as any,
      });

      setChatRoom(room);

      // Connect to the chat room
      room.connect();
    }
  };
  const findCurrentClipTime = (el: any, index: any) => {
    let sum = 0;
    for (var i = 0; i < index; i++) {
      sum += el?.duration;
    }
    return sum;
  };
  function isTokenExpired(expirationDate: any) {
    const currentDate = moment();
    const tokenExpirationDate = moment(expirationDate);

    return tokenExpirationDate.isBefore(currentDate);
  }
  const handleChangeIcon = (name: any) => {
    const item: any = {};
    item[name] = true;
    setIsEdit(false);
    setCurrentClip(null);
    setActiveIcon({ ...item });
  };

  const jumpToTime = (time: any) => {
    if (playerRef.current) {
      const maxTime = playerRef.current.getMaxTimeShift();
      if (playerRef.current.isLive() || gameData?.is_live) {
        playerRef.current.timeShift(maxTime + time);
      } else {
        playerRef.current.seek(time);
      }
    }
  };
  const handleClipPost = async (name: string) => {
    try {
      const unique_id = uuid();
      const clips = clipsData || [];
      const currentPosition = Math.round(
        playerRef.current.getCurrentTime("relativetime")
      );

      const start_time = currentPosition - 5;
      const end_time = start_time + 20;
      const postClip = {
        video_clip_uuid: unique_id,
        start_time: start_time <= 0 ? 0 : start_time,
        end_time: end_time,
        name: name,
        category: name,
        duration: 20,
        active: true,
        createdAt: moment(),
        game_details: {
          video_url:
            videoAway && gameDetails?.watch_url_away
              ? gameDetails?.watch_url_away
              : gameDetails?.watch_url,
          vod_url:
            videoAway && gameDetails?.vod_url_away
              ? gameDetails?.vod_url_away
              : gameDetails?.vod_url,
          game_id: gameId,
          away_team: gameDetails.away_team,
          home_team: gameDetails.home_team,
          game_date_time: gameDetails.game_date_time,
        },
      };

      clips?.push(postClip);
      const data = {
        game_id: gameId,
        videoUrl:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
        unique_id: unique_id,
        clips: clips,
        name: `Created ${name}`,
        status: "CREATE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);
      GetClipsDetails();
      handleChangeIcon("plays");
    } catch (error) {}
  };
  const handleDelete = async (video_clip_uuid: any) => {
    try {
      const clips = clipsData || [];

      const postClip: any = clips?.findIndex(
        (el) => el.video_clip_uuid == video_clip_uuid
      );
      const name = clips[postClip].name;
      clips.splice(postClip, 1);

      const data = {
        game_id: gameId,
        clips: clips,
        name: `Deleted ${name}`,
        status: "DELETE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);
      GetClipsDetails();
    } catch (error) {}
  };

  const handleSubmitData = async (value: any, video_clip_uuid: any) => {
    if (!value.name) {
      toast.error("Name is required");
      return;
    }
    if (value?.duration < 20 || value?.duration > 60) {
      toast.error(
        "Duration should be less than 1 min and greater than 20 sec "
      );
      return;
    }
    try {
      const clips = clipsData || [];
      const unique_id = uuid();
      const postClip: any = clips?.findIndex(
        (el) => el.video_clip_uuid == video_clip_uuid
      );
      const name = clips[postClip].name;

      clips[postClip].active = false;

      const data2 = {
        game_id: gameId,
        clips: clips,
        name: `Deleted ${name}`,
        status: "DELETE",
      };

      const res2 = await HttpService.post(APIRoutes.PostClipDetailas, data2);

      const postClip2 = {
        video_clip_uuid: unique_id,
        start_time: value.start_time,
        end_time: value.end_time,
        name: value?.name,
        category: value?.category,
        duration: value.end_time - value.start_time || 20,
        active: true,
        createdAt: moment(),
        game_details: {
          video_url:
            videoAway && gameDetails?.watch_url_away
              ? gameDetails?.watch_url_away
              : gameDetails?.watch_url,
          game_id: gameId,
          away_team: gameDetails.away_team,
          home_team: gameDetails.home_team,
          game_date_time: gameDetails.game_date_time,
        },
      };

      clips?.push(postClip2);
      const data = {
        game_id: gameId,
        videoUrl:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
        unique_id: unique_id,
        clips: clips,
        name: `Created ${name}`,
        status: "CREATE",
      };

      const res = await HttpService.post(APIRoutes.PostClipDetailas, data);

      GetClipsDetails();
      setIsEdit(false);
    } catch (error) {}
  };

  const skipForward = (time: any) => {
    if (playerRef.current) {
      const currentPosition = playerRef.current.getCurrentTime("relativetime");
      const currentPositionLive = playerRef.current.getMaxTimeShift();

      if (playerRef.current.isLive() || gameData?.is_live) {
        if (-currentPosition - currentPositionLive > time) {
          playerRef.current.timeShift(
            currentPositionLive + currentPosition + time
          );
        }
      } else {
        playerRef.current.seek(currentPosition + time);
      }
    }
  };

  const rewind = (time: any) => {
    if (playerRef.current) {
      const currentPosition = playerRef.current.getCurrentTime("relativetime");
      const currentPositionLive = playerRef.current.getMaxTimeShift();
      if (playerRef.current.isLive() || gameData?.is_live) {
        playerRef.current.timeShift(
          currentPositionLive + currentPosition - time
        );
      } else {
        playerRef.current.seek(currentPosition - time);
      }
    }
  };
  const getDuration = () => {
    return currentClip.end_time - currentClip.start_time;
  };
  const GetClipsDetails = async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetVideoClipsDetails.replace(":id", gameId)
      );
      setClipsData(res.data.data);
    } catch (error) {}
  };
  const dispatch = useDispatch();
  const startConnecting = async () => {
    try {
      const meeting_id = meetingIdFromUrl;

      const meetingDetails = await HttpService.post(APIRoutes.JoinParty, {
        meetingId: meeting_id,
      });

      const connect = false ? WT.Session.connectAsAViewer : WT.Session.connect;
      const room = {
        token: meetingDetails?.data?.data?.SyncToken,
        clientName: `${userDetails.FirstName} ${userDetails.LastName}`,
        video:
          videoAway && gameDetails?.watch_url_away
            ? gameDetails?.watch_url_away
            : gameDetails?.watch_url,
      };

      connect(
        meetingDetails?.data?.data.WTToken,
        `${userDetails.FirstName} ${userDetails.LastName}`,
        {
          audio: true,
          video: true,
        }
      );

      dispatch(roomDetails.actions.setRoomDetails(room));
      handleSubmit(
        meetingDetails?.data?.data?.SyncToken,
        `${userDetails.FirstName} ${userDetails.LastName}`
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    GetClipsDetails();
    initializeSyncSdk();
    GetHighLightDetailsForOld();
    if (process.env.REACT_APP_FEATURE == "true") {
      GetAccessToken();
    }
    if (searchParams.get("meeting_id")) {
      handleChangeIcon("watch");
      startConnecting();
    } else {
      dispatch(roomDetails.actions.removeRoomDetails());
    }
    on("error", handleError);

    return () => {
      off("error", handleError);
    };
  }, []);
  const handleDurationChange = (dur: number, isStart: boolean) => {
    if (
      (!isStart &&
        (currentClip.end_time - currentClip.start_time + dur < 20 ||
          currentClip.end_time - currentClip.start_time + dur > 60)) ||
      (isStart &&
        (currentClip.end_time - currentClip.start_time - dur < 20 ||
          currentClip.end_time - currentClip.start_time - dur > 60))
    ) {
      toast.error("Duration should be less than 1 min and greater than 20 sec");
      return;
    }
    if (currentClip) {
      if (isStart) {
        setCurrentClip({
          ...currentClip,
          start_time:
            currentClip.start_time + dur <= 0
              ? 0
              : currentClip.start_time + dur,
        });
      } else {
        setCurrentClip({
          ...currentClip,
          end_time: currentClip.end_time + dur,
        });
      }
    }
  };
  const GetAccessToken = async () => {
    try {
      const res = await PersonalChannelApi.GetLiniusAccessToken(
        APIRoutes.GetLiniusAccessToken
      );
      if (res?.data?.data) {
        localStorage.setItem(
          "persist:root",
          JSON.stringify({
            authSlice: JSON.stringify({
              accessToken: res?.data?.data.accessToken,
            }),
            userSlice: JSON.stringify({
              apiKey: configData.xApiKey,
            }),
          })
        );
        setLiniusAccessToken(res?.data?.data);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (process.env.REACT_APP_FEATURE == "true") {
      GetHighLightDetails();
      GetAllLinusDownloadVideo();
    }
  }, [GetHighLightDetails]);

  return (
    <Box>
      <CustomChangeButton checked={checked} setChecked={setChecked} />

      <Box sx={{ display: "none" }}>
        <WatchTogether />{" "}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={checked ? 12 : 8.5}>
          <Box sx={{ px: 1 }}>
            {activeIcon?.highlight &&
            process.env.REACT_APP_FEATURE == "true" ? (
              <VideoPlayerForChannel
                page={page}
                checked={checked}
                pageData={pageData}
                sideBarComponent={
                  <VideoPlayerSideBar
                    handleChangeIcon={handleChangeIcon}
                    activeIcon={activeIcon}
                    clipsButton={
                      <ClipsButtons
                        handleClipPost={handleClipPost}
                        rewind={rewind}
                        isSmall={true}
                        skipForward={skipForward}
                        playVideo={playVideo}
                        running={running}
                        pauseVideo={pauseVideo}
                      />
                    }
                    connectRoom={connectRoom}
                    gameDetails={gameDetails}
                    gameData={gameData}
                    liveData={liveData}
                    hightLighData={hightLighData}
                    getDuration={getDuration}
                    setSearch={setSearch}
                    isEdit={isEdit}
                    search={search}
                    checked={checked}
                    currentClip={currentClip}
                    handleDurationChange={handleDurationChange}
                    handleChangeClips={handleChangeClips}
                    setCurrentClip={setCurrentClip}
                    handleSubmitData={handleSubmitData}
                    handleTabChangeAction={handleTabChangeAction}
                    setIsEdit={setIsEdit}
                    handleDelete={handleDelete}
                    clipsData={clipsData}
                    videoAway={videoAway}
                    subscriptionDetails={subscriptionDetails}
                    playerRef={playerRef}
                    GetClipsDetails={GetClipsDetails}
                    setIsDownLoadStarted={setIsDownLoadStarted}
                    isDownloadStarted={isDownloadStarted}
                    jumpToTime={jumpToTime}
                    selectedTabAction={selectedTabAction}
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                    isDownloadAll={isDownloadAll}
                    thubmnails={thubmnails}
                    videoList={videoList}
                    handleChange={handleChange}
                    page={page}
                    pageData={pageData}
                    isLoading={isLoading}
                    playerRefLinius={playerRefLinius}
                    findCurrentClipTime={findCurrentClipTime}
                    chatRoom={chatRoom}
                    setIsDownloadAll={setIsDownloadAll}
                    activeClip={activeClip}
                    allDownloadVideo={allDownloadVideo}
                  />
                }
                setActiveClip={setActiveClip}
                setPage={setPage}
                setCurrentTime={setCurrentTime}
                playerRef={playerRefLinius}
                videoRef={videoRef}
                currentVideoData={currentVideoData}
                setCurrentVideoData={setCurrentVideoData}
                videoList={videoList}
                assetId={currentVideoData?.assetId}
                offset={currentVideoData?.offset}
                duration={currentVideoData?.duration}
                accessToken={liniusAccessToken?.accessToken}
                xApiKey={liniusAccessToken?.xApiKey}
              />
            ) : (
              <Box>
                <BitMovingPlayer
                  checked={checked}
                  handleChangeIconAction={() => handleChangeIcon("action")}
                  gameDetails={gameDetails}
                  setRunning={setRunning}
                  sideBarComponent={
                    <VideoPlayerSideBar
                      handleChangeIcon={handleChangeIcon}
                      activeIcon={activeIcon}
                      clipsButton={
                        <ClipsButtons
                          handleClipPost={handleClipPost}
                          rewind={rewind}
                          isSmall={true}
                          skipForward={skipForward}
                          playVideo={playVideo}
                          running={running}
                          pauseVideo={pauseVideo}
                        />
                      }
                      connectRoom={connectRoom}
                      gameDetails={gameDetails}
                      gameData={gameData}
                      liveData={liveData}
                      hightLighData={hightLighData}
                      getDuration={getDuration}
                      setSearch={setSearch}
                      isEdit={isEdit}
                      search={search}
                      checked={checked}
                      currentClip={currentClip}
                      handleDurationChange={handleDurationChange}
                      handleChangeClips={handleChangeClips}
                      setCurrentClip={setCurrentClip}
                      handleSubmitData={handleSubmitData}
                      handleTabChangeAction={handleTabChangeAction}
                      setIsEdit={setIsEdit}
                      handleDelete={handleDelete}
                      clipsData={clipsData}
                      videoAway={videoAway}
                      subscriptionDetails={subscriptionDetails}
                      playerRef={playerRef}
                      GetClipsDetails={GetClipsDetails}
                      setIsDownLoadStarted={setIsDownLoadStarted}
                      isDownloadStarted={isDownloadStarted}
                      jumpToTime={jumpToTime}
                      selectedTabAction={selectedTabAction}
                      selectedTab={selectedTab}
                      handleTabChange={handleTabChange}
                      isDownloadAll={isDownloadAll}
                      thubmnails={thubmnails}
                      videoList={videoList}
                      handleChange={handleChange}
                      page={page}
                      pageData={pageData}
                      isLoading={isLoading}
                      playerRefLinius={playerRefLinius}
                      findCurrentClipTime={findCurrentClipTime}
                      chatRoom={chatRoom}
                      setIsDownloadAll={setIsDownloadAll}
                      activeClip={activeClip}
                      allDownloadVideo={allDownloadVideo}
                    />
                  }
                  setIsSeeking={() => setIsSeeking(!isSeeking)}
                  videoAway={videoAway}
                  videoUrl={
                    videoAway && gameDetails?.watch_url_away
                      ? gameDetails?.watch_url_away
                      : gameDetails?.watch_url
                  }
                  playerRef={playerRef}
                  autoPlay={true}
                />
              </Box>
            )}
          </Box>

          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "center", px: { xs: 1, lg: 0 } }}
          >
            {gameDetails?.watch_url_away && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    ml: 1,
                    justifyContent: "center",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      sx={{ textTransform: "none" }}
                    >
                      Home Feed
                    </Typography>
                    <Switch
                      checked={videoAway}
                      onChange={(e: any) => {
                        setVideoAway(e.target.checked);
                      }}
                    />
                    <Typography
                      fontWeight="600"
                      variant="body2"
                      sx={{ textTransform: "none" }}
                    >
                      Away Feed
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
          {!checked && (
            <ClipsButtons
              handleClipPost={handleClipPost}
              rewind={rewind}
              skipForward={skipForward}
              playVideo={playVideo}
              running={running}
              isSmall={false}
              pauseVideo={pauseVideo}
            />
          )}

          <Box
            sx={{
              textAlign: "start",
              display: "flex",
              alignItems: "center",
              py: 3,
              gap: 2,
            }}
          >
            <Box>
              <img width={"100px"} src={gameDetails?.card_logo} />
            </Box>

            <Box>
              {!gameDetails?.card_image_url && (
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 600,
                    color: configData.primary,
                  }}
                >
                  {" "}
                  {gameDetails.away_team} @ {gameDetails.home_team}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  color: configData.primary,
                  fontWeight: 600,
                  fontStyle: "normal",
                }}
              >
                {gameDetails?.card_description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: configData.primary,
                  fontWeight: 600,
                  fontStyle: "normal",
                }}
              >
                {" "}
                {momentTimezone
                  .tz(
                    gameDetails.game_date_time,
                    "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                    "America/New_York"
                  )
                  .tz(userTimeZone)
                  .format("h:mm a z")}{" "}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {!checked && (
          <Grid item xs={12} md={3.5}>
            <VideoPlayerSideBar
              handleChangeIcon={handleChangeIcon}
              activeIcon={activeIcon}
              connectRoom={connectRoom}
              gameDetails={gameDetails}
              gameData={gameData}
              liveData={liveData}
              hightLighData={hightLighData}
              getDuration={getDuration}
              setSearch={setSearch}
              isEdit={isEdit}
              search={search}
              currentClip={currentClip}
              handleDurationChange={handleDurationChange}
              handleChangeClips={handleChangeClips}
              setCurrentClip={setCurrentClip}
              handleSubmitData={handleSubmitData}
              handleTabChangeAction={handleTabChangeAction}
              setIsEdit={setIsEdit}
              handleDelete={handleDelete}
              clipsData={clipsData}
              videoAway={videoAway}
              subscriptionDetails={subscriptionDetails}
              playerRef={playerRef}
              GetClipsDetails={GetClipsDetails}
              setIsDownLoadStarted={setIsDownLoadStarted}
              isDownloadStarted={isDownloadStarted}
              jumpToTime={jumpToTime}
              selectedTabAction={selectedTabAction}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              isDownloadAll={isDownloadAll}
              thubmnails={thubmnails}
              videoList={videoList}
              handleChange={handleChange}
              page={page}
              pageData={pageData}
              isLoading={isLoading}
              playerRefLinius={playerRefLinius}
              findCurrentClipTime={findCurrentClipTime}
              chatRoom={chatRoom}
              setIsDownloadAll={setIsDownloadAll}
              activeClip={activeClip}
              allDownloadVideo={allDownloadVideo}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default VideoPlayerLive;
