import { Pause, PlayArrow } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import useConfig from "../../../hooks/useConfig";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
const ClipsButtons = ({
  handleClipPost,
  rewind,
  skipForward,
  playVideo,
  running,
  pauseVideo,
  isSmall,
}: any) => {
  const configData = useConfig();
  const commomStyle = {
    textTransform: "none",
    fontStyle: "normal",
    ":hover": {
      bgcolor: configData.primary,
    },
  };
  const props = {
    xs: 6,
    sm: 4,
    md: isSmall ? 4 : 3,
  };
  const buttonPros = {
    xs: isSmall ? 4 : 2,
    lg: isSmall ? 4 : 1.8,
    md: isSmall ? 4 : 2,
  };
  return (
    <Grid sx={{ p: 1, mt: 1 }} spacing={1.5} container>
      <Grid container item xs={12} spacing={1.5}>
        <Grid item {...buttonPros}>
          <Button
            variant="contained"
            sx={commomStyle}
            onClick={() => rewind(10)}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastRewindIcon />
            {"10"}
          </Button>
        </Grid>
        <Grid item {...buttonPros}>
          <Button
            onClick={() => rewind(3)}
            variant="contained"
            sx={commomStyle}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastRewindIcon />
            {"3"}
          </Button>
        </Grid>
        <Grid item {...buttonPros}>
          <Button
            onClick={() => rewind(1)}
            variant="contained"
            sx={commomStyle}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastRewindIcon />
            {"1"}
          </Button>
        </Grid>
        <Grid
          sx={{ display: { lg: "block", xs: "none" } }}
          item
          xs={12}
          md={isSmall ? 12 : 1.2}
        >
          {running ? (
            <Button
              variant="contained"
              sx={commomStyle}
              className={`video-button ${configData.buttonCommonClass}`}
              style={{ fontWeight: "800" }}
              onClick={() => pauseVideo()}
            >
              <Pause />
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={commomStyle}
              className={`video-button ${configData.buttonCommonClass}`}
              style={{ fontWeight: "800" }}
              onClick={() => playVideo()}
            >
              <PlayArrow />
            </Button>
          )}
        </Grid>
        <Grid item {...buttonPros}>
          <Button
            onClick={() => skipForward(1)}
            variant="contained"
            sx={commomStyle}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastForwardIcon /> {"1"}
          </Button>
        </Grid>
        <Grid item {...buttonPros}>
          <Button
            onClick={() => skipForward(3)}
            variant="contained"
            sx={commomStyle}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastForwardIcon />
            {"3"}
          </Button>
        </Grid>{" "}
        <Grid item {...buttonPros}>
          <Button
            onClick={() => skipForward(10)}
            variant="contained"
            sx={commomStyle}
            className={`video-button ${configData.buttonCommonClass}`}
          >
            <FastForwardIcon /> {"10"}
          </Button>
        </Grid>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("goal");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Goal
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("shot");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Shot
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("save");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Save
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("highlight");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Highlight
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("faceoff");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Faceoff
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("hit");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Hit
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("penalty");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Penalty
        </Button>
      </Grid>
      <Grid item {...props}>
        <Button
          onClick={() => {
            handleClipPost("custom");
          }}
          variant="contained"
          sx={commomStyle}
          className={`video-button ${configData.buttonCommonClass}`}
        >
          Custom
        </Button>
      </Grid>
    </Grid>
  );
};
export default ClipsButtons;
